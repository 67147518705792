import { Illustration } from '../missions/components/MissionIllustrations/MissionIllustrations';
import Clothes1 from '../assets/images/intro-missione-1-02.png';
import Clothes2 from '../assets/images/intro-missione-1-01.png';
import Investigation1 from '../assets/images/intro-missione-2-02.png';
import Investigation2 from '../assets/images/intro-missione-2-01.png';
import Message1 from '../assets/images/intro-missione-3-01.svg';
import Message2 from '../assets/images/intro-missione-3-02.svg';

type IllustrationType = 'clothes' | 'investigations' | 'messages';

const useIllustrationsAsset = (type: IllustrationType): [Illustration, Illustration] => {
  switch (type) {
    case 'clothes':
      return [
        { src: Clothes2, alt: 'illustrazione maglietta' },
        { src: Clothes1, alt: 'illustrazione jeans' },
      ];
    case 'investigations':
      return [
        { src: Investigation2, alt: 'illustrazione foto' },
        { src: Investigation1, alt: 'illustrazione fascicolo' },
      ];
    case 'messages':
      return [
        { src: Message2, alt: 'illustrazione messaggio azzurro' },
        { src: Message1, alt: 'illustrazione messaggio viola' },
      ];
  }
};

export default useIllustrationsAsset;

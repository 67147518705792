import { useState } from 'react';
import shuffle from '../../../utils/Shuffle';
import puzzle1 from '../../../assets/images/puzzle_item_1.jpg';
import puzzle2 from '../../../assets/images/puzzle_item_2.jpg';
import puzzle3 from '../../../assets/images/puzzle_item_3.jpg';
import puzzle4 from '../../../assets/images/puzzle_item_4.jpg';
import puzzle5 from '../../../assets/images/puzzle_item_5.jpg';
import puzzle6 from '../../../assets/images/puzzle_item_6.jpg';
import puzzle7 from '../../../assets/images/puzzle_item_7.jpg';
import puzzle8 from '../../../assets/images/puzzle_item_8.jpg';
import puzzle9 from '../../../assets/images/puzzle_item_9.jpg';

type PuzzleItems = {
  label: string;
  path: string;
}[];

type UseAssetReturn = {
  orderedAsset: PuzzleItems;
  shuffledAsset: PuzzleItems;
};

const orderedAsset: PuzzleItems = [
  { label: 'Puzzle 1', path: puzzle1 },
  { label: 'Puzzle 2', path: puzzle2 },
  { label: 'Puzzle 3', path: puzzle3 },
  { label: 'Puzzle 4', path: puzzle4 },
  { label: 'Puzzle 5', path: puzzle5 },
  { label: 'Puzzle 6', path: puzzle6 },
  { label: 'Puzzle 7', path: puzzle7 },
  { label: 'Puzzle 8', path: puzzle8 },
  { label: 'Puzzle 9', path: puzzle9 },
];

const useAsset = (): UseAssetReturn => {
  const tempAsset = [...orderedAsset];
  const [shuffledAsset] = useState(shuffle(tempAsset));
  return { orderedAsset, shuffledAsset };
};

export default useAsset;

import React, { useRef } from 'react';
import { Link } from 'react-router-dom';
import './style.scss';
import lock from '../../assets/icons/lock.svg';
import useMissionsHandler from '../../hooks/useMissionsHandler';
import getClasses from '../../utils/getClasses';
import brandManitese from '../../assets/images/manitese-logo.svg';
import brandCariplo from '../../assets/images/cariplo-logo.svg';
import Facebook from '../../assets/icons/facebook.svg';
import Instagram from '../../assets/icons/instagram.svg';
import Twitter from '../../assets/icons/twitter.svg';
import useFocus from '../../hooks/useFocus';
import IconLinkButton from '../IconLinkButton/IconLinkButton';

const Footer = (): JSX.Element => {
  const { result } = useMissionsHandler();
  const intro = useRef<HTMLAnchorElement | null>(null);
  const mission1 = useRef<HTMLAnchorElement | null>(null);
  const mission2 = useRef<HTMLAnchorElement | null>(null);
  const mission3 = useRef<HTMLAnchorElement | null>(null);
  const about = useRef<HTMLAnchorElement | null>(null);
  const privacyPolicy = useRef<HTMLAnchorElement | null>(null);
  const cookiePolicy = useRef<HTMLAnchorElement | null>(null);

  useFocus(intro, 'with-keyboard');
  useFocus(mission1, 'with-keyboard');
  useFocus(mission2, 'with-keyboard');
  useFocus(mission3, 'with-keyboard');
  useFocus(about, 'with-keyboard');
  useFocus(privacyPolicy, 'with-keyboard');
  useFocus(cookiePolicy, 'with-keyboard');

  return (
    <footer className="footer container-fluid">
      <div className="row">
        <div className="col-xl-3 footer-col-wrapper">
          <div className="container-nav-brand">
            <Link to="/" className="svg-wrapper">
              <svg viewBox="0 0 160 160" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="currentColor" className="svg-container">
                <defs>
                  <path id="path" d="M160 80C160 124.183 124.183 160 80 160C35.8172 160 0 124.183 0 80C0 35.8172 35.8172 0 80 0C124.183 0 160 35.8172 160 80Z" fill="#C4C4C4"/>
                </defs>
                <text fill="currentColor" className="header-1">
                  <textPath href="#path" xlinkHref="#path"  textLength="500">
                    Vesti il futuro — Vesti il futuro —
                  </textPath>
                </text>
              </svg>
            </Link>
            <p className="m-0 paragraph">Vesti il futuro nasce dall’idea che ogni individuo possa fare la differenza sul pianeta. Prova anche tu ad arricchire il mondo in cui vivi con piccoli gesti consapevoli.</p>
          </div>
        </div>
        <div className="col-xl-2 offset-xl-1 footer-col-wrapper">
          <label className="nav-label">Scopri</label>
          <ul className="nav footer-nav">
            <li className="nav-item">
              <Link to="/" className="nav-link" ref={intro}>
                Intro
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/missions/1" className="nav-link" ref={mission1}>
                Missione 1
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to="/missions/2"
                className={getClasses({
                  'nav-link': true,
                  'disabled': !result.completed?.includes('1')
                })}
                tabIndex={!result.completed?.includes('1') ? -1 : 0}
                ref={mission2}
              >
                Missione 2
                {!result.completed?.includes('1') && (
                  <svg viewBox="0 0 24 25" className="icon-lock">
                    <use href={lock + '#lock-icon'}></use>
                  </svg>
                )}
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to="/missions/3"
                className={getClasses({
                  'nav-link': true,
                  'disabled': !result.completed?.includes('2')
                })}
                tabIndex={!result.completed?.includes('2') ? -1 : 0}
                ref={mission3}
              >
                Missione 3
                {!result.completed?.includes('2') && (
                  <svg viewBox="0 0 24 25" className="icon-lock">
                    <use href={lock + '#lock-icon'}></use>
                  </svg>
                )}
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/about" className="nav-link" ref={about}>
                About
              </Link>
            </li>
          </ul>
        </div>
        <div className="col-xl-3 footer-col-wrapper">
        <label className="nav-label">Un progetto realizzato da</label>
        <div className="container-brands">
          <a 
            href="https://www.manitese.it"
            target="_blank"
            rel="noreferrer"
            className="brand-link"
          >
            <img src={brandManitese} alt="manitese logo"/>
          </a>
          <a
            className="brand-link"
            href="https://www.fondazionecariplo.it/it/index.html"
            target="_blank"
            rel="noreferrer"
          >
            <img src={brandCariplo} alt="fondazione cariplo logo"/>
          </a>
        </div>
        </div>
        <div className="col-xl-2 offset-xl-1 footer-col-wrapper">
          <ul className="nav footer-underline-nav">
            <li className="nav-item">
              <a
                href="https://www.manitese.it/privacy-cookies-policy"
                target="_blank"
                rel="noreferrer"
                className="nav-link"
                ref={privacyPolicy}
              >
                Privacy policy
              </a>
            </li>
            <li className="nav-item">
              <a
                href="https://www.manitese.it/privacy-cookies-policy#cookies"
                target="_blank"
                rel="noreferrer"
                className="nav-link"
                ref={cookiePolicy}
              >
                Cookie policy
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div className="row">
        <div className="col-xl-3">
          <div className="container-socials footer-col-wrapper">
            <ul className="nav socials-nav">
              <li className="nav-item">
                <IconLinkButton
                  href="https://www.facebook.com/ManiTese/"
                  target="_blank"
                  rel="noreferrer"
                  icon={`${Facebook}#facebook`}
                />
              </li>
              <li className="nav-item">
                <IconLinkButton
                  href="https://www.instagram.com/mani_tese/?hl=it"
                  target="_blank"
                  rel="noreferrer"
                  icon={`${Instagram}#instagram`}
                />
              </li>
              <li className="nav-item">
                <IconLinkButton
                  href="https://twitter.com/ManiTese?ref_src=twsrc%5Egoogle%7Ctwcamp%5Eserp%7Ctwgr%5Eauthor"
                  target="_blank"
                  rel="noreferrer"
                  icon={`${Twitter}#twitter`}
                />
              </li>
            </ul>
          </div>
        </div>
        <div className="col-xl-5 offset-xl-1 footer-col-wrapper">
          <p className="m-0 paragraph-extrasmall">
            Questo sito è stato realizzato grazie al contributo dell'Agenzia Italiana per la Cooperazione allo Sviluppo, nell'ambito del progetto AID 011798. I contenuti riportati sono di esclusiva responsabilità delle organizzazioni che li hanno prodotti e non rappresentano necessariamente il punto di vista dell’Agenzia.
          </p>
        </div>
        <div className="col-xl-2 offset-xl-1">
          <p className="m-0 paragraph-extrasmall">© 2021 Mani Tese</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;

import React from 'react';
import { useHistory } from 'react-router-dom';
import useMissionsHandler from '../../../hooks/useMissionsHandler';
import styles from './MissionCounterParagraph.module.scss';

interface MissionCounterParagraphProps {
  className?: string;
}

const MissionCounterParagraph: React.FC<MissionCounterParagraphProps> = ({ className }) => {
  const { result } = useMissionsHandler();
  const { location } = useHistory();

  return (
    <p className={`paragraph-uppercase ${styles['paragraph']} ${className}`}>{`Missione ${location.pathname.split('/')[2]}/${result.counter}`}</p>
  );
};

export default MissionCounterParagraph;

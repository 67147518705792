import { useState } from 'react';
import shuffle from '../../../utils/Shuffle';
import clothes1 from '../../../assets/images/clothes-01.svg';
import clothes2 from '../../../assets/images/clothes-02.svg';
import clothes3 from '../../../assets/images/clothes-03.svg';
import clothes4 from '../../../assets/images/clothes-04.svg';
import clothes5 from '../../../assets/images/clothes-05.svg';
import clothes6 from '../../../assets/images/clothes-06.svg';

type Clothes = {
  label: string;
  path: string;
}[];

const asset: Clothes = [
  { label: 'Vestito 1', path: clothes1 },
  { label: 'Vestito 2', path: clothes2 },
  { label: 'Vestito 3', path: clothes3 },
  { label: 'Vestito 4', path: clothes4 },
  { label: 'Vestito 5', path: clothes5 },
  { label: 'Vestito 6', path: clothes6 },
];

const useAsset = (): Clothes => {
  const [duplicateAsset] = useState(shuffle([...asset, ...asset]));
  return duplicateAsset;
};

export default useAsset;

import gsap from 'gsap';

const stagger = (target: gsap.TweenTarget, colums: number, order: 'start' | 'end' = 'start'): gsap.core.Tween => {
  return gsap.to(target, {
    opacity: order === 'start' ? 1 : 0,
    delay: 0.5,
    stagger: (index, _, list) => {
      const row = index / colums;
      const col = index % colums;

      if (order === 'end') {
        const lastElementPosition = {
          row: (list.length - 1) / colums,
          column: (list.length - 1) % colums,
        };
        return (Math.floor((lastElementPosition.row + lastElementPosition.column) * 1) - Math.floor((row + col) * 1)) * 0.05;  
      }

      return Math.floor((row + col) * 1) * 0.05;
    },
  });
};


export default stagger;

import './IntroStepTwo.scss';
import React from 'react';
import Background from '../../../../assets/lotties/bg-tempo-metà.json';
import FlamesBackground from '../../../../assets/lotties/fiamme.json';
import WaterBackground from '../../../../assets/lotties/acqua.json';
import Lottie from 'react-lottie';
import EllipsedText from 'src/components/EllipsedText/EllipsedText';
import ScrollIndicator from '../ScrollIndicator/ScrollIndicator';

const IntroStepTwo: React.FC = () => {
  return (
    <>
      <div className="content blue">
        <div className="cover">
          <Lottie
            speed={0.5}
            options={{
              animationData: Background,
              loop: true,
              autoplay: true,
              rendererSettings: { preserveAspectRatio: 'xMidYMid slice' },
            }}
          />
        </div>
        <div className="cover">
          <Lottie
            options={{
              animationData: FlamesBackground,
              loop: true,
              autoplay: true,
              rendererSettings: { preserveAspectRatio: 'xMidYMid slice' },
            }}
          />
        </div>
        <div className="cover">
          <Lottie
            options={{
              animationData: WaterBackground,
              loop: true,
              autoplay: true,
              rendererSettings: { preserveAspectRatio: 'xMidYMid slice' },
            }}
          />
        </div>
        <div className="rocks-container" />
        <div className="description">
          <ScrollIndicator />
          <EllipsedText type="intro-step-one">
            <h1 className="header-2 label">La terra</h1>
          </EllipsedText>
          <p className="paragraph description-paragraph">
            La superficie terrestre è devastata, è una distesa di vestiti sparpagliati e l’aria è irrespirabile. Nel
            sottosuolo i pochi esseri umani rimasti lottano per la sopravvivenza relegati in angusti bunker.
          </p>
        </div>
      </div>
    </>
  );
};

export default IntroStepTwo;

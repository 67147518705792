import React from 'react';
import getClasses from '../../../../utils/getClasses';
import styles from './ItemList.module.scss';

interface ItemListProps {
  image: string;
  label: string;
  title: string;
  paragraph: string;
}

const ItemList = ({ image, label, title, paragraph }: ItemListProps): JSX.Element => {
  return (
    <div className={styles.container}>
      <img className={styles.image} src={image} alt={label} />
      <h3 className={getClasses({ [styles.title]: true, 'header-3': true })}>{title}</h3>
      <p>{paragraph}</p>
    </div>
  );
};

export default ItemList;

import React, { useRef } from 'react';
import IconLink from '../../components/IconLink/IconLink';
import colors from '../../assets/scss/colors.module.scss';
import ArrowBack from '../../assets/icons/arrow.svg';
import ClothesCover from '../../assets/images/clothes-cover.svg';
import './MemoryMission.scss';
import useAsset from './hooks/useAsset';
import useMemory from './hooks/useMemory';
import { CommonInnerNativagionProps } from '../../hooks/useInnerNavigator';

const description = 'Accoppia le card per eliminare i vestiti non sostenibili dal tuo armadio.';

const MemoryMission: React.FC<Omit<CommonInnerNativagionProps, 'active'>> = ({ onComplete, goBack }) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const cardsContainerRef = useRef<HTMLDivElement>(null);
  const asset = useAsset();
  const classes = {
    card: 'card',
    description: 'description',
    link: 'link-wrapper',
    flipped: 'flipped',
    hasMatch: 'has-match',
    noEvent: 'no-event',
  };

  useMemory({ ref: cardsContainerRef, classes, onComplete, goBack });

  return (
    <div ref={containerRef} className="primary-container container-fluid custom-container memory-mission">
      <div className="row">
        <div className="col-12 col-lg-10 offset-lg-1 btn-position">
          <IconLink 
            text="Indietro"
            upperCase
            color={colors.flatDarkBlue}
            icon={`${ArrowBack}#arrow-icon`}
          />
          <div className="content row">
            <p className="paragraph-bold description">{description}</p>
            <div ref={cardsContainerRef} className="grid no-event col-10 col-md-7 col-xl-6 col-xxl-4">
              {asset.map(({ label, path }, index) => (
                <div
                  data-memory={label}
                  key={`memory-mission-card-${index}`}
                  className="card flipped">
                  <img className="image front" src={ClothesCover} alt="cover" />
                  <img className="image back" src={path} alt={label} />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MemoryMission;

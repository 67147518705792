import { useState } from 'react';

type Form<T> = { [K in keyof T]: T[K] };
type FormReturnType<T> = {
  form: Partial<Form<T>>;
  setForm: (key: keyof T, value: T[keyof T]) => void;
  submit: (callback: (form: Form<T>) => void) => void;
  errors: Partial<{ [x in keyof T]: string | undefined }>;
};
interface FormValidationItem<T, K extends keyof T> {
  required?: string;
  custom?: (value: T[K]) => string | undefined;
}

type FormValidationObject<T> = { [K in keyof T]: FormValidationItem<T, K> };

const useForm = <T>(initialValue: Form<T>, validators?: FormValidationObject<T>): FormReturnType<T> => {
  const [form, setForm] = useState<Form<T>>(initialValue);
  const [errors, setErrors] = useState<{ [x in keyof T]: string | undefined }>(Object.assign(form));

  const handleError = (key: keyof T, value: string | undefined) => {
    setErrors((oldErrors) => ({
      ...oldErrors,
      [key]: value,
    }));
  };

  const onChange = (key: keyof T, value: T[keyof T]) => {
    handleError(key, undefined);
    setForm((oldForm) => ({ ...oldForm, [key]: value }));
  };

  const submit = (callback?: (fields: Form<T>) => void) => {
    let valids: boolean[] = [];
    if (validators) {
      valids = Object.keys(form).map((key) => {
        const currentKey = key as keyof T;
        const currentField = form[currentKey];
        const validator = validators[currentKey];
        if (validator) {
          if (
            validator.required &&
            (typeof currentField === 'undefined' ||
              (typeof currentField === 'string' && currentField.length === 0))
          ) {
            handleError(currentKey, validator.required);
            return false;
          } else if (validator.custom) {
            const customError = validator.custom(currentField);
            handleError(currentKey, customError);
            return !customError;
          }
        }
        return true;
      });
    }

    if (valids.every(Boolean) && callback) {
      callback(form);
    }
  };

  return {
    form,
    setForm: onChange,
    submit,
    errors,
  };
};

export default useForm;

import React, { useLayoutEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import Lottie from 'react-lottie';
import { gsap, Back } from 'gsap';
import './StartMissionPage.scss';
import { Routes } from '../../App';
import TextButton from '../../components/TextButton/TextButton';
import Background from '../../assets/lotties/stelle_vetrine_bg.json';
import Play from '../../assets/icons/play.svg';

const StartMissionPage: React.FC = () => {
  const history = useHistory();
  const tl = useRef<gsap.core.Timeline | null>(null);
  const goToMission = () => history.push(`${Routes.Missions}/1`);
  const ref = useRef<HTMLDivElement | null>(null);
  const q = gsap.utils.selector(ref);

  useLayoutEffect(() => {
    tl.current = gsap.timeline()
    .fromTo(q('.door-mask'),
      { opacity: 0 },
      {
        opacity: 1,
        duration: 1,
        ease: Back.easeInOut,
      })
    .fromTo(q('.text-path'),
    {
      attr: { startOffset: '0', textLength: 415 },
      opacity: 0,
    },
     {
      attr: { startOffset: '24%', textLength: 415 },
      duration: 1,
      opacity: 1,
      ease: Back.easeInOut,
    }, 0.2)
    .fromTo(q('.page-description'),
      { opacity: 0 },
      {
        opacity: 1,
        duration: 0.8,
      }, 0.5)
    .fromTo(
      q('.content-label'),
      {
        opacity: 0,
      },
      {
        opacity: 1,
        duration: 0.8,
      }, 0.8);

      return () => {
        tl.current?.kill();
      };
  }, [tl]);

  return (
    <div className="container-fluid container-start-missions" ref={ref}>
      <div className="row">
        <div className="col-lg-6 offset-lg-3 container-mission">
          <div className="container-door">
            <svg
              data-text="Pianeta Terra, 3002"
              viewBox="0 0 265 441"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="door-svg">
              <defs>
                <path id="doorPath" d="M0.1,441V132.4C0.1,132.4,1,0,132.5,0s132.4,132.4,132.4,132.4V441H0.1z" />
              </defs>
              <text id="text" fill="currentColor" dy="-5%" className="header-1">
                <textPath
                  className="text-path"
                  xlinkHref="#path"
                  href="#doorPath"
                  startOffset="0%"
                  textLength={500}
                  >
                  2021-odissea nel passato
                </textPath>
              </text>
            </svg>
            <div className="door-mask">
              <Lottie
                options={{
                  animationData: Background,
                  loop: true,
                  autoplay: true,
                  rendererSettings: { preserveAspectRatio: 'xMidYMid slice' },
                }}
              />
            </div>
          </div>
          <p className="paragraph page-description">La corsa spasmodica delle industrie di abbigliamento all’ultimo trend e la produzione di massa di capi a basso costo hanno inquinato aria, acqua e terra. Ma c’è un modo per cambiare le cose.</p>
          <TextButton
            text="Missione 1/3"
            onClick={goToMission}
            svgPath={`${Play}#play`}
          />
        </div>
      </div>
    </div>
  );
};

export default StartMissionPage;

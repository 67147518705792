import React, { useLayoutEffect } from 'react';
import './AboutPage.scss';
import brandManitese from '../../assets/images/manitese-logo-dark.svg';
import brandCariplo from '../../assets/images/cariplo-logo-dark.svg';
import { useHistory } from 'react-router-dom';
import { Routes } from '../../App';
import TextButton from '../../components/TextButton/TextButton';
import { fadeIn } from 'src/utils/fadeElements';

const AboutPage: React.FC = () => {
  const history = useHistory();
  const goToSubscription = () => history.push(Routes.Subscription);


  useLayoutEffect(() => {
    const anim = fadeIn(['.about-container .fade-anim']);
    return () => {
      anim.time(0).kill();
    };
  }, []);

  return (
    <>
      <div className="container-fluid about-container">
        <div className="row">
          <div className="col-lg-8 offset-lg-2">
            <h1 className="title-extra fade-anim">About</h1>
            <p className="paragraph-bold fade-anim">Un progetto realizzato da</p>
            <div className="container-brands">
              <a 
                href="https://www.manitese.it"
                target="_blank"
                rel="noreferrer"
                className="brand-link"
              >
                <img className="manitese-brand fade-anim" src={brandManitese} alt="manitese logo"/>
              </a>
              <a
                className="brand-link"
                href="https://www.fondazionecariplo.it/it/index.html"
                target="_blank"
                rel="noreferrer"
              >
                <img className="cariplo-brand fade-anim" src={brandCariplo} alt="fondazione cariplo logo"/>
              </a>
            </div>
            <div className="row">
              <div className="col-md-6">
                <p className="paragraph fade-anim">Crediamo nel valore delle persone e pensiamo che il contributo di ciascuno sia fondamentale per fare la differenza.</p>
                <p className="paragraph fade-anim">Da oltre 50 anni la nostra ONG si batte per la giustizia sociale, economica e ambientale nel mondo. Nel corso degli anni abbiamo promosso campagne, realizzato progetti di cooperazione, organizzato iniziative di educazione ed esperienze concrete di economia solidale grazie al sostegno di migliaia di persone.</p>
              </div>
              <div className="col-md-6">
                <p className="paragraph fade-anim">Ma c’è ancora tanto da fare. Per far parte del cambiamento</p>
                <p className="paragraph fade-anim">Per far parte del cambiamento serve la collaborazione di tutti. Ecco perché cerchiamo volontari, attivisti e sostenitori che credano nei nostri valori e nella possibilità concreta di creare una società basata sull’uguaglianza e sul rispetto dei diritti umani e dell’ambiente.</p>
                <p className="paragraph fade-anim">Entra anche tu a far parte di questo movimento!</p>
              </div>
            </div>
            <div className="actions-container fade-anim">
              <TextButton
                text="Unisciti al cambiamento"
                onClick={goToSubscription}
              />
            </div>
          </div>
        </div>
      </div>
    
    </>
  );
};

export default AboutPage;

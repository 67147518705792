import { Checkbox, FormControlLabel, makeStyles } from '@material-ui/core';
import React from 'react';
import CheckBoxProps from './CheckBox.models';
import colors from '../../assets/scss/colors.module.scss';
import getClasses from '../../utils/getClasses';

const getStyles = makeStyles({
  root: {
    '&.MuiCheckbox-root': {
      color: colors.flatDarkPurple,
    },
    '&.Mui-error': {
      color: colors.flatError,
    },
  }
});

const CheckBox: React.FC<CheckBoxProps> = ({ error, label, onChange }) => {
  const classes = getStyles();
  return (
    <FormControlLabel
      control={<Checkbox className={getClasses({ 'Mui-error': error })} classes={classes} onChange={(e) => onChange(e.target.checked)} />}
      label={label}
    />
  );
};

export default CheckBox;

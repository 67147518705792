import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import useMissionsHandler from '../../hooks/useMissionsHandler';
import PuzzleMission from '../../missions/PuzzleMission/PuzzleMission';
import useIllustrationsAsset from '../../hooks/useIllustrationsAsset';
import useInnerNavigator from '../../hooks/useInnerNavigator';
import MissionStart from '../../missions/components/MissionStart/MissionStart';
import SecondMissionEnd from './components/SecondMissionEnd/SecondMissionEnd';
import { Routes } from '../../App';

const SecondMissionPage: React.FC = () => {
  const history = useHistory();
  const [innerNavigator, setInnerNavigator] = useInnerNavigator();
  const { onComplete } = useMissionsHandler();

  const title = 'Crea consapevolezza';
  const description =
    'È necessario che tutti conoscano i rischi reali legati alla fast fashion, ma il documento che conteneva le informazioni sui crimini dell’industria tessile è stato distrutto.';
  const endDescription = 'La tua missione è ricostruirlo, recuperando il contenuto, e divulgarlo.';
  const illustrations = useIllustrationsAsset('investigations');

  useEffect(() => {
    /* TODO: quando diventano della pagine separate eliminare questo codice */
    window.scrollTo(0, 0);
  }, [innerNavigator]);

  return (
    <>
      {innerNavigator === 'start' && (
        <MissionStart
          title={title}
          description={description}
          endDescription={endDescription}
          illustrations={illustrations}
          onComplete={() => setInnerNavigator('in-progress')}
        />
      )}
      {innerNavigator === 'in-progress' && (
        <PuzzleMission
          goBack={() => setInnerNavigator('start')}
          onComplete={() => {
            onComplete(history.location.pathname.split('/')[2]);
            setInnerNavigator('completed');
          }}
        />
      )}
      {innerNavigator === 'completed' && <SecondMissionEnd onComplete={() => history.replace('3')} />}
    </>
  );
};

export default SecondMissionPage;

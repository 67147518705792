import React, { useEffect } from 'react';
import styles from './SecondMissionEnd.module.scss';
import { CommonInnerNativagionProps } from '../../../../hooks/useInnerNavigator';
import { useMediaQuery } from 'react-responsive';
import MissionCounterParagraph from '../../../../missions/components/MissionCounterParagraph/MissionCounterParagraph';
import getClasses from '../../../../utils/getClasses';
import RoundButton from '../../../../components/RoundButton/RoundButton';
import MissionIllustrations from '../../../../missions/components/MissionIllustrations/MissionIllustrations';
import useIllustrationsAsset from '../../../../hooks/useIllustrationsAsset';
import gsap from 'gsap';
import { fadeIn, fadeOut } from '../../../../utils/fadeElements';
import ItemList from '../ItemList/ItemList';
import waterImage from '../../../../assets/images/water.svg';
import wavesImage from '../../../../assets/images/waves.svg';
import fireImage from '../../../../assets/images/fire.svg';
import pollutionImage from '../../../../assets/images/pollution.svg';
import EllipsedText from '../../../../components/EllipsedText/EllipsedText';

const description =
  'Per salvaguardare il pianeta occorre allargare lo sguardo e fare attenzione alle conseguenze che ogni singola scelta di consumo ha sulla natura e sugli altri.';

const list = [
  {
    image: wavesImage,
    label: 'Icona onde 1',
    title: 'Mezzo milione di microplastiche inquinano gli oceani ogni anno',
    paragraph:
      'L\'industria dell\'abbigliamento è il secondo settore produttivo più inquinante. Ogni anno, mezzo milione di tonnellate di microfibre di plastica vengono versate negli oceani solo attraverso il lavaggio di nylon e poliestere.',
  },
  {
    image: fireImage,
    label: 'Icona fiamma',
    title: '2.625 kg di vestiti bruciano al secondo',
    paragraph:
      'Ogni secondo, nel mondo, vengono bruciati 2.625 kg di capi per un totale di quasi 83 miliardi di kg di abiti l\'anno, equivalenti a circa mezzo grattacielo di New York.',
  },
  {
    image: pollutionImage,
    label: 'Icona inquinamento',
    title: '1,2 miliardi di tonnellate di CO2 ogni anno',
    paragraph:
      'L\'emissione di CO2 per la produzione del tessile corrisponde a 1,2 miliardi di tonnellate l\'anno, quantitativo che supera il totale degli scarichi prodotti dai voli aerei e dalle navi.',
  },
  {
    image: waterImage,
    label: 'Icona goccia',
    title: '15000 litri d’acqua per un paio di jeans',
    paragraph:
      'Per la produzione di un singolo paio di jeans vengono impiegati 15.000 litri di acqua e 15 vasche di tintura per tingere la stoffa.',
  },
  {
    image: wavesImage,
    label: 'Icona onde 2',
    title: 'Corsi d\'acqua avvelenati da sostanze tossiche',
    paragraph:
      'Il 70% di fiumi e laghi sono inquinati da quasi 9,5 miliardi di litri di liquidi di scarto dell’industria tessile.',
  },
  {
    image: wavesImage,
    label: 'Icona onde 3',
    title: '7.000 litri di acqua è il costo reale di una t-shirt',
    paragraph:
      'Il cotone con cui è realizzata una singola t-shirt viene raccolto in regioni afflitte da siccità e necessita di 7.000 litri di acqua per essere coltivato.',
  },
];

const descriptionEnd = 'Adesso hai tutto ciò che ti serve per passare alla prossima missione.';

const SecondMissionEnd: React.FC<Pick<CommonInnerNativagionProps, 'onComplete'>> = ({ onComplete }): JSX.Element => {
  const hiddenMissionCounter = useMediaQuery({ minDeviceWidth: 768 });
  const thumbnailMissionIllustrations = useMediaQuery({ minDeviceWidth: 1024 });
  const targetsToFade = [
    `.${styles['mission-counter']}`,
    `.${styles.title}`,
    `.${styles.description}`,
    `.${styles.list}`,
    `.${styles['description-end']}`,
  ];
  const illustrations = useIllustrationsAsset('investigations');

  useEffect(() => {
    gsap.to(`.${styles['btn-container']}`, { opacity: 1, duration: 0.7, delay: 0.1 });
    gsap.to('.image', {
      opacity: 1,
      duration: 0.7,
      delay: 0.3,
      stagger: (index) => [0.3, 0.4][index],
    });
    fadeIn(targetsToFade);
  }, []);

  const onTap = () => {
    gsap.to(`.${styles['btn-container']}`, { opacity: 0, duration: 0.7, delay: 0.8 }).then(onComplete);
    gsap.to('.image', {
      opacity: 0,
      duration: 0.7,
      delay: 0.4,
      stagger: (index) => [0.3, 0.4][index],
    });
    fadeOut(targetsToFade);
  };

  return (
    <div className={`primary-container ${styles['custom-container']} container-fluid`}>
      <div className={styles.header}>
        <MissionIllustrations hidden illustrations={illustrations} thumbnail={thumbnailMissionIllustrations} />
        <MissionCounterParagraph
          className={getClasses({ [styles['mission-counter']]: true, [styles.hidden]: hiddenMissionCounter })}
        />
        <div className={styles.title}>
          <EllipsedText type="mission-end-title-container">
            <h1 className="title-extra">Missione compiuta!</h1>
          </EllipsedText>
        </div>
      </div>
      <div className={styles.content}>
        <p className={`paragraph ${styles.description}`}>{description}</p>
        <div className={`row ${styles.list}`}>
          {list.map(({ image, label, title, paragraph }, index) => (
            <div key={`conseguenze-${index}`} className="col-12 col-md-6">
              <ItemList image={image} label={label} title={title} paragraph={paragraph} />
            </div>
          ))}
        </div>
        <p className={`paragraph-bold ${styles['description-end']}`}>{descriptionEnd}</p>
      </div>
      <div className={styles['btn-container']}>
        <RoundButton onClick={onTap} />
      </div>
    </div>
  );
};

export default SecondMissionEnd;

import React, { useRef } from 'react';
import styles from './PuzzleMission.module.scss';
import colors from '../../assets/scss/colors.module.scss';
import ArrowBack from '../../assets/icons/arrow.svg';
import useAsset from './hooks/useAsset';
import { CommonInnerNativagionProps } from '../../hooks/useInnerNavigator';
import IconLink from '../../components/IconLink/IconLink';
import usePuzzle from './hooks/usePuzzle';

const description = 'La tua missione è ricostruirlo, recuperando il contenuto, e divulgarlo.';

const PuzzleMission: React.FC<Omit<CommonInnerNativagionProps, 'active'>> = ({ onComplete, goBack }) => {
  const gridRef = useRef<HTMLDivElement>(null);
  const backgroundRef = useRef<HTMLDivElement>(null);
  const { shuffledAsset } = useAsset();
  const classes = {
    link: 'link-wrapper',
    description: styles.description,
    background: styles.background,
    card: styles.card,
    image: styles.image,
    helper: styles.helper,
    noInteraction: styles['no-interaction'],
  };

  usePuzzle({ gridRef, classes, onComplete, goBack });

  return (
    <div className={`primary-container ${styles['custom-container']}`}>
      <IconLink 
        text="Indietro"
        upperCase
        color={colors.flatDarkBlue}
        icon={`${ArrowBack}#arrow-icon`}
      />
      <div className={styles.content}>
        <p className={`paragraph-bold ${styles.description}`}>{description}</p>
        <div className={styles['grid-container']}>
          <div ref={gridRef} className={styles.grid}>
            <div ref={backgroundRef} className={styles.background} />
            {shuffledAsset.map(({ label, path }, index) => (
              <div data-puzzle={label} key={`puzzle-mission-card-${index}`} className={styles.card}>
                <img className={styles.image} src={path} alt={label} />
              </div>
            ))}
          </div>
          <button className={`btn text-button ${styles.helper}`}>Immagine di riferimento</button>
        </div>
      </div>
    </div>
  );
};

export default PuzzleMission;

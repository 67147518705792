import React, { useLayoutEffect, useRef } from 'react';
import { gsap, Linear } from 'gsap';
import { DrawSVGPlugin } from 'gsap/all';
import './style.scss';
import getClasses from '../../utils/getClasses';

interface EllipsedTextProps {
  type?:
    | 'default'
    | 'mission-end-title-container'
    | 'subscription-title-container'
    | 'intro-step-one'
    | 'third-mission-end';
}

const EllipsedText: React.FC<EllipsedTextProps> = ({ type = 'default', children }) => {
  const tl = useRef<gsap.core.Timeline | null>(null);
  const ellipse = useRef<SVGPathElement>(null);

  useLayoutEffect(() => {
    gsap.registerPlugin(DrawSVGPlugin);
    tl.current = gsap
      .timeline({ ease: Linear.easeIn })
      .fromTo(ellipse.current, { drawSVG: '75% 75%' }, { delay: 0.8, duration: 0.4, drawSVG: '-25% 75%' });
    return () => {
      tl.current?.kill();
    };
  }, []);

  return (
    <div
      className={getClasses({
        'text-with-ellipse-container': true,
        [type]: type !== 'default',
      })}>
      <div className="cover-animated">
        <svg
          viewBox="0 0 218 65"
          height="100%"
          width="100%"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          preserveAspectRatio="none">
          <filter id="filter">
            <feTurbulence baseFrequency="0.2 0.8" type="turbulence" numOctaves="5" result="turbulence" seed="25" />
            <feDisplacementMap in="SourceGraphic" scale="4" in2="turbulence" />
          </filter>
          <path
            ref={ellipse}
            d="M213.25 30C213.25 33.7548 210.529 37.5048 205.177 41.0522C199.855 44.5804 192.103 47.7867 182.458 50.4911C163.178 55.8968 136.499 59.25 107 59.25C77.5011 59.25 50.8224 55.8968 31.542 50.4911C21.8966 47.7867 14.1454 44.5804 8.82297 41.0522C3.47146 37.5048 0.75 33.7548 0.75 30C0.75 26.2452 3.47146 22.4952 8.82297 18.9478C14.1454 15.4196 21.8966 12.2133 31.542 9.50895C50.8224 4.10323 77.5011 0.75 107 0.75C136.499 0.75 163.178 4.10323 182.458 9.50895C192.103 12.2133 199.855 15.4196 205.177 18.9478C210.529 22.4952 213.25 26.2452 213.25 30Z"
            strokeWidth="2"
            filter="url(#filter)"
            stroke="currentColor"
          />
        </svg>
      </div>

      <div
        className={getClasses({
          'text-container': true,
          [type]: type !== 'default',
        })}>
        {children}
      </div>
    </div>
  );
};

export default EllipsedText;

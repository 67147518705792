import { useCallback, useEffect, useState } from 'react';
import { AudioStatus } from '../components/AudioButton/AudioButton.models';

type UseAudioReturnType = {
  element: HTMLAudioElement;
  status: AudioStatus;
  play: () => void;
  stop: () => void;
  pause: () => void;
  toggle: () => void;
};

const useAudio = (audio: HTMLAudioElement): UseAudioReturnType => {
  const [status, setStatus] = useState<AudioStatus>('stopped');

  useEffect(() => {
    const playHandler = () => setStatus('played');
    const pauseHandler = () => {
      setStatus(audio.currentTime === 0 ? 'stopped' : 'paused');
    };

    if (audio) {
      audio.load();
      audio.addEventListener('play', playHandler);
      audio.addEventListener('pause', pauseHandler);

      return () => {
        audio.removeEventListener('play', playHandler);
        audio.removeEventListener('pause', pauseHandler);
      };
    }
  }, [audio]);

  const handler = useCallback(() => {
    if (document.visibilityState === 'visible' && status !== 'stopped') {
      if (localStorage.getItem('audioToggle') === 'true') {
        audio.play();
      }
    } else {
      localStorage.setItem('audioToggle', status === 'played' ? 'true' : 'false');
      audio.pause();
    }
  }, [status]);

  useEffect(() => {
    document.addEventListener('visibilitychange', handler);

    return () => {
      document.removeEventListener('visibilitychange', handler);
    };
  }, [handler]);

  const play = () => {
    audio.loop = true;
    audio.play();
  };

  const pause = () => audio.pause();

  const stop = () => {
    audio.pause();
    audio.currentTime = 0;
  };

  const toggle = () => {
    if (status === 'played') {
      audio.pause();
    } else {
      play();
    }
  };

  return { element: audio, status, play, pause, stop, toggle };
};

export default useAudio;

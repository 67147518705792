import gsap from 'gsap';
import React from 'react';
import { useEffect } from 'react';
import './Planets.scss';
import earth from '../../../../assets/lotties/earth.json';
import moonSVG from '../../../../assets/images/moon.svg';
import marsSVG from '../../../../assets/images/mars.svg';
import AnimatedPlanetElement from './Planets.models';
import Lottie from 'react-lottie';

const Planets: React.FC = () => {
  const animationElements: AnimatedPlanetElement[] = [
    {
      className: 'earth',
      duration: 200,
    },
    {
      className: 'mars',
      duration: 250,
    },
    {
      className: 'moon',
      duration: 100,
    },
  ];

  useEffect(() => {
    const animate = ({ className, duration }: AnimatedPlanetElement) => {
      gsap.to(`.${className}`, {
        duration,
        repeat: -1,
        ease: 'linear',
        rotation: 360,
      });
    };

    animationElements.forEach(animate);
  }, []);

  return (
    <div className="planets-container">
      <div className="planet earth">
        <Lottie
          options={{
            animationData: earth,
            loop: true,
            autoplay: true,
            rendererSettings: { preserveAspectRatio: 'xMidYMid slice' },
          }}
        />
      </div>
      <img className="planet moon" src={moonSVG} alt="pianeta luna" />
      <img className="planet mars" src={marsSVG} alt="pianeta marte" />
    </div>
  );
};

export default Planets;

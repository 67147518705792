import React, { useLayoutEffect, useRef, useState } from 'react';
import { gsap, Linear } from 'gsap';
import './style.scss';
import RoundButtonProps from './RoundButton.models';
import useFocus from '../../hooks/useFocus';

const RoundButton = ({ onClick, color }: RoundButtonProps): JSX.Element => {
  const [completed, setCompleted] = useState<boolean>();
  const [rootElement, setRootElement] = useState<HTMLElement | null>();
  const tl = useRef<gsap.core.Timeline | null>(null);
  const roundButton = useRef<HTMLButtonElement | null>(null);
  const q = gsap.utils.selector(roundButton);
  useFocus(roundButton, 'with-keyboard');

  /**
   * reference animation
   * - radial animation https://greensock.com/forums/topic/17566-create-a-radial-wipe/
   * - text-path https://greensock.com/forums/topic/20929-animating-text-in-on-a-path/
   */

  const initTimeline = () => {
    setCompleted(false);
    tl.current?.to(q('.circle-container'),{
      r: 35,
      duration: .4,
      ease: Linear.easeNone,
      repeat: -1,
      yoyo: true
    })
    .to(q('.circle'), {
      r: 10,
      duration: .4,
      ease: Linear.easeNone,
      repeat: -1, 
      yoyo: true
    }, '<.15');
  };

  const mouseEnterHandler = () => {
    tl.current?.clear().to(q('.circle-container'), {
      r: 60,
      duration: .4,
      ease: Linear.easeNone,
    })
    .to(q('.circle'), {
      r: 20,
      duration: .4,
      ease: Linear.easeNone,
    },0);
  };

  const mouseLeaveHandler = () => {
    mouseUpHandler();
    tl.current?.clear().to(q('.circle-container'), {
      r: 45,
      duration: .3,
      ease: Linear.easeNone,
    })
    .to(q('.circle'), {
      r: 20,
      duration: .3,
      ease: Linear.easeNone,
      onComplete: () => initTimeline() 
    }, 0);
  };
 
  const mouseDownHandler = () => {
    rootElement?.classList.add('disable-text-selection');
    const arc = {  
      start: 0,
      end: 360,
      cx: 80,
      cy: 80,
      r: 60 
    };
    
    tl.current?.to(arc, {
      end: 0,
      ease: Linear.easeNone,
      onUpdate: () => roundButton.current?.querySelector('#arcPath')?.setAttribute('d', getPath(arc.cx, arc.cy, arc.r, arc.start, arc.end)), 
      duration: .5,
      onComplete: () => setCompleted(true)
    });
  };

  const mouseUpHandler = () => {
    if(!completed){
      roundButton.current?.querySelector('#arcPath')?.setAttribute('d','M 10 80 a 70 70 0 1 0 140 0 a 70 70 0 1 0 -140 0z');
      tl.current?.clear();
    }
    else {
      onClick();
    }
    rootElement?.classList.remove('disable-text-selection');
  };
  
  const getPath = (cx: number, cy: number, r: number, a1: number, a2: number) => {
    const RAD  = Math.PI / 180;
    const PI_2 = Math.PI / 2;
    const delta = a2 - a1;
    
    if (delta === 360) { 
      return 'M ' + (cx - r) + ' ' + cy + ' a ' + r + ' ' + r + ' 0 1 0 ' + r * 2 + ' 0 a ' + r + ' ' + r + ' 0 1 0 ' + -r * 2 + ' 0z'; 
    }
    
    const largeArc = delta > 180 ? 1 : 0;
      
    a1 = a1 * RAD - PI_2;
    a2 = a2 * RAD - PI_2;
  
    const x1 = cx + r * Math.cos(a2);   
    const y1 = cy + r * Math.sin(a2);
  
    const x2 = cx + r * Math.cos(a1); 
    const y2 = cy + r * Math.sin(a1);
      
    return 'M ' + x1 + ' ' + y1 + ' A ' + r + ' ' + r + ' 0 ' + largeArc + ' 0 ' + x2 + ' ' + y2 + ' L ' + cx + ' ' + cy + 'z';
  };
  
  useLayoutEffect(() => {
    setRootElement(document.querySelector<HTMLElement>('html'));
    const rotate = gsap.to(q('.text-svg'), {
      duration: 6, 
      repeat: -1,
      ease: Linear.easeInOut,
      rotation: 360,
      transformOrigin:'center center'
    });
    tl.current = gsap.timeline()
      .to(q('.circle-container'),{
        r: 45,
        duration: .25,
        ease: Linear.easeNone,
      }, '<')
      .to(q('.circle'), {
        r: 20,
        duration: .25,
        ease: Linear.easeNone,
      }, '<.15');
  
    initTimeline();

    return () => {
      rotate.kill();
      tl.current?.kill();
    };
  }, []);

  return (
    <button 
      onPointerEnter={mouseEnterHandler}
      onPointerLeave={mouseLeaveHandler}
      onPointerUp={mouseUpHandler}
      onPointerDown={mouseDownHandler}
      className="round-button btn" 
      ref={roundButton}
      style={{ color: color }}
    >
      <svg width="88" height="88" viewBox="0 0 160 160" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="currentColor" className="round-button-wrapper">
        <defs>
          <path id="path" d="M160 80C160 124.183 124.183 160 80 160C35.8172 160 0 124.183 0 80C0 35.8172 35.8172 0 80 0C124.183 0 160 35.8172 160 80Z"/>   
          <clipPath id="clipArc">
            <path id="arcPath" d="M 10 80 a 70 70 0 1 0 140 0 a 70 70 0 1 0 -140 0z"/>
          </clipPath>
        </defs>
        <text className="header-1 text-svg" fill="currentColor" stroke="none">
          <textPath xlinkHref="#path" href="#path" textLength="500">
            Tieni premuto — Tieni premuto —
          </textPath>
        </text>
        <g stroke="none">
          <circle cx="80" cy="80" r="0" fill="currentColor" className="circle-container" 
            opacity="0.5" clipPath="url(#clipArc)"/>
          <circle cx="80" cy="80" r="0" fill="currentColor" className="circle"/>
        </g>
      </svg>
    </button>
  );
};

export default RoundButton;

import React, { useLayoutEffect, useRef } from 'react';
import { gsap, Quad } from 'gsap';
import './Preloader.scss';
import scssColor from '../../../../assets/scss/colors.module.scss';
import TextButton from '../../../../components/TextButton/TextButton';
import PreloaderProps from './Preloader.models';
import brandCariplo from '../../../../assets/images/cariplo-logo.svg';

const Preloader: React.FC<PreloaderProps> = ({ onComplete, play }) => {
  const tl = useRef<gsap.core.Timeline | null>(null);
  const preloaderContainerRef = useRef<HTMLDivElement | null>(null);
  const q = gsap.utils.selector(preloaderContainerRef);

  useLayoutEffect(() => {
    const rotate = gsap.to(q('.text-svg'), {
      duration: 6,
      repeat: -1,
      ease: 'linear',
      rotation: '360',
      transformOrigin: 'center center',
    });

    tl.current = gsap.timeline();
    tl.current.to(q('.container-loader'), {
      translateY: 0,
      duration: 0.8,
      ease: Quad.easeInOut,
    })
    .to(q('.description-ref'), {
      opacity: 1,
      ease: Quad.easeInOut,
    }, 0)
    .to(q('.svg-wrapper'), {
      color: scssColor.flatLightPurple,
      duration: 1,
      ease: Quad.easeInOut,
    }, 0);

    return () => {
      rotate.kill();
      tl.current?.kill();
    };
  }, []);

  const closePreloader = () => {
    play();
    tl.current?.to(q('.container-loader'), {
      yPercent: -100,
      duration: 0.8,
      ease: Quad.easeInOut,
      onStart: onComplete,
    })
    .to(
      preloaderContainerRef.current,
      {
        opacity: 0,
        ease: Quad.easeInOut,
      },
      '<',
    )
    .to(preloaderContainerRef.current, {
      display: 'none',
    });
  };

  return (
    <>
      <div className="preloader-container" ref={preloaderContainerRef}>
        <div className="preloader-wrapper">
          <div className="svg-wrapper">
            <svg
              width="150"
              height="150"
              viewBox="0 0 160 160"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="svg-container">
              <defs>
                <path
                  id="path"
                  d="M160 80C160 124.183 124.183 160 80 160C35.8172 160 0 124.183 0 80C0 35.8172 35.8172 0 80 0C124.183 0 160 35.8172 160 80Z"
                  fill="#C4C4C4"
                />
              </defs>
              <text fill="currentColor" className="text-svg">
                <textPath href="#path" xlinkHref="#path" textLength={500}>
                  Vesti il futuro
                  <tspan> — </tspan>
                  Vesti il futuro
                  <tspan> — </tspan>
                </textPath>
              </text>
            </svg>
          </div>
          <div className="description-container">
            <div className="description description-ref">
              <p className="paragraph-regular paragraph-description">Un progetto realizzato da Mani Tese con il contributo di</p>
              <img src={brandCariplo} alt="fondazione cariplo logo" />
            </div>
            <TextButton text="Entra" color={scssColor.flatLightPurple} onClick={closePreloader}></TextButton>
          </div>
        </div>
        <div className="container-loader"></div>
      </div>
    </>
  );
};

export default Preloader;

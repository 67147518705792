import './ThirdMissionEnd.scss';
import React, { useCallback, useLayoutEffect } from 'react';
import EllipsedText from '../../../../components/EllipsedText/EllipsedText';
import getClasses from '../../../../utils/getClasses';
import { useMediaQuery } from 'react-responsive';
import RoundButton from '../../../../components/RoundButton/RoundButton';
import { CommonInnerNativagionProps } from '../../../../hooks/useInnerNavigator';
import gsap from 'gsap';
import { fadeIn, fadeOut } from 'src/utils/fadeElements';

const ThirdMissionEnd: React.FC<Pick<CommonInnerNativagionProps, 'onComplete'>> = ({ onComplete }) => {
  const isTablet = useMediaQuery({ minDeviceWidth: 768 });
  const targetsToFade = ['.text-with-ellipse-container', '.description'];

  useLayoutEffect(() => {
    const fadeInAnimation = fadeIn(targetsToFade);
    const roundBtnAnimation = gsap.fromTo('.round-btn-container', { opacity: 0 }, { opacity: 1, duration: 0.7, delay: 1 });

    return () => {
      fadeInAnimation.kill();
      roundBtnAnimation.kill();
    };
  }, []);

  const onSubmit = useCallback(() => {
    let roundBtnAnimation: gsap.core.Tween; 

    const fadeOutAnimation = fadeOut(targetsToFade);
    fadeOutAnimation.then(() => {
      roundBtnAnimation = gsap.to('.round-btn-container', { opacity: 0 });
      roundBtnAnimation.then(onComplete);
    });

    return () => {
      fadeOutAnimation.kill();
      roundBtnAnimation?.kill();
    };
  }, []);

  return (
    <div className="primary-container third-mission-end-container">
      <div className="content">
        <EllipsedText type="third-mission-end">
          <h1
            className={getClasses({
              'header-1': !isTablet,
              'title-extra': isTablet,
            })}>
            Hai portato a termine tutte le missioni.
          </h1>
        </EllipsedText>
        <p
          className={getClasses({
            description: true,
            paragraph: !isTablet,
            'paragraph-large': isTablet,
          })}>
          Torna nel futuro per scoprire se hai salvato il pianeta Terra.
        </p>
      </div>
      <div className="round-btn-container">
        <RoundButton onClick={onSubmit} />
      </div>
    </div>
  );
};

export default ThirdMissionEnd;

import gsap from 'gsap';

type FunctionBasedValue = (index: number, target: unknown, targets: unknown[]) => number;

const translateY = 80;
const durationForItem = 0.2;
const delay = 0.1;
const defaultStagger = ((index: number) => (index + 1) * durationForItem);

export const fadeIn = (targets: gsap.TweenTarget[], onStagger?: FunctionBasedValue): gsap.core.Tween => {
  const stagger = onStagger || defaultStagger;
  return gsap.fromTo(`${targets.join()}`, { y: translateY, opacity: 0 }, { opacity: 1, y: 0, stagger: 0.08, duration: 0.8 });
};

export const fadeOut = (targets: gsap.TweenTarget[], onStagger?: FunctionBasedValue): gsap.core.Tween => {
  const stagger = onStagger || defaultStagger;
  return gsap.to(`${targets.join()}`, { delay, opacity: 0, y: -translateY, stagger });
};

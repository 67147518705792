import { useState, useLayoutEffect, useCallback } from 'react';
interface WindowsDimensions {
  width: number;
  height: number;
}

const useWindowDimensions = (): WindowsDimensions => {
  const [size, setSize] = useState({
    width: 0,
    height: 0,
  });

  const updateSize = useCallback(
    () => {
      setSize({
        width: window.innerWidth,
        height: window.innerHeight
      });
    },
    [window],
  );

  useLayoutEffect(() => {
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);
  
  return size;
};

export default useWindowDimensions;

import React, { useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import './style.scss';
import useFocus from '../../hooks/useFocus';
import mouseEnterHandlerAnimation from '../../utils/mouseEnterHandlerAnimation';
import IconLinkProps from './IconLink.models';
import { Link } from 'react-router-dom';
import getClasses from '../../utils/getClasses';

const IconLink = ({ color, text, to = {}, upperCase, icon, onClick }: IconLinkProps): JSX.Element => {
  const ref = useRef<HTMLAnchorElement>(null);
  const tl = useRef<gsap.core.Timeline | null>(null);
  useFocus(ref, 'with-keyboard');

  useEffect(() => {
    tl.current = gsap.timeline();

    return () => {
      tl.current?.kill();
    };
  }, [ref]);

  return (
    <div className="link-wrapper">
      <Link
        to={to}
        className={getClasses({ 
          'text-button-small link-container btn': true,
          'uppercase': upperCase
        })}
        ref={ref}
        style={{ color }}
        onClick={(e) => {
          if (onClick) {
            e.stopPropagation();
            onClick();
          } 
        }}
        onMouseEnter={() => mouseEnterHandlerAnimation(tl, ref)}
      >
        {icon && (
          <svg viewBox="0 0 24 24" className="svg-icon">
            <use xlinkHref={icon} href={icon}></use>
          </svg>
        )}
        {text}
      </Link>
    </div>
  );
};

export default IconLink;

import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import './style.scss';
import lock from '../../assets/icons/lock.svg';
import arrow from '../../assets/icons/arrow.svg';
import { gsap, Quad } from 'gsap';
import { ScrollTrigger } from 'gsap/all';
import * as bootstrap from 'bootstrap';
import getClasses from '../../utils/getClasses';
import { Link, useHistory, useLocation } from 'react-router-dom';
import useMissionsHandler from '../../hooks/useMissionsHandler';
import TextButton from '../TextButton/TextButton';
import useFocus from '../../hooks/useFocus';
import { Routes } from '../../App';
import AudioButton from '../AudioButton/AudioButton';
import useAudio from '../../hooks/useAudio';
import { useMediaQuery } from 'react-responsive';

const Navbar = ({ status, toggle: audioToggle }: ReturnType<typeof useAudio>): JSX.Element => {
  const history = useHistory();
  const [toggle, setToggle] = useState(false);
  const tl = useRef<gsap.core.Timeline | null>(null);
  const toggleMenu = () => setToggle((oldValue) => !oldValue);
  const { result } = useMissionsHandler();
  const tooltips = useRef<bootstrap.Tooltip[]>([]);
  const intro = useRef<HTMLAnchorElement | null>(null);
  const mission1 = useRef<HTMLAnchorElement | null>(null);
  const mission2 = useRef<HTMLAnchorElement | null>(null);
  const mission3 = useRef<HTMLAnchorElement | null>(null);
  const about = useRef<HTMLAnchorElement | null>(null);
  const location = useLocation();
  useFocus(intro, 'with-keyboard');
  useFocus(mission1, 'with-keyboard');
  useFocus(mission2, 'with-keyboard');
  useFocus(mission3, 'with-keyboard');
  useFocus(about, 'with-keyboard');
  const isDesktop = useMediaQuery({ minDeviceWidth: 1024 });

  const initTimeline = () => {
    ScrollTrigger.matchMedia({
      '(max-width: 1024px)': () => {
        tl.current?.from('.navbar-container .nav-item, .navbar-container .nav-link-subtitle', {
          y: 50,
          opacity: 0,
          duration: 0.5,
          ease: Quad.easeInOut,
          stagger: 0.2,
        });
      },
    });
  };

  const goToSubscription = () => {
    setToggle(false);
    history.push(Routes.Subscription);
  };

  useLayoutEffect(() => {
    gsap.registerPlugin(ScrollTrigger);
    tl.current = gsap.timeline({ paused: true });
    initTimeline();
    const tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
    tooltips.current = tooltipTriggerList.map((tooltipTriggerEl) => new bootstrap.Tooltip(tooltipTriggerEl));

    return () => {
      tl.current?.kill();
    };
  }, []);

  useEffect(() => {
    const navbar = document.getElementById('navbar-container');
    if (navbar) {
      navbar.classList.toggle('menu-open', toggle);
      document.body.classList.toggle('navbar-open', toggle);
    }
    toggle ? tl.current?.restart() : tl.current?.seek(tl.current?.totalDuration());
  }, [toggle]);

  useEffect(() => {
    tooltips.current?.forEach((tooltip, index) =>
      result.completed?.includes(String(index + 1)) ? tooltip.disable() : tooltip.enable(),
    );
  }, [result.completed]);

  return (
    <nav className="navbar navbar-expand-lg navbar-light navbar-container" id="navbar-container">
      <div className="container-fluid">
        <div className="navbar-brand-container">
          <Link className="navbar-brand header-3" to={Routes.Root}>
            Vesti il futuro
          </Link>
          <button className="navbar-toggler" type="button" onClick={toggleMenu}>
            <span className="icon-bar top-bar"></span>
            <span className="icon-bar middle-bar"></span>
            <span className="icon-bar bottom-bar"></span>
          </button>
        </div>
        <div className="collapse navbar-collapse container-fluid" id="navbarNavAltMarkup">
          <ul className="navbar-nav">
            <li className="nav-item">
              <Link
                onClick={() => setToggle(false)}
                to="/"
                className={getClasses({
                  'nav-link': true,
                  'menu-item': true,
                  active: location.pathname === '/',
                })}
                aria-current="page"
                data-text="Intro"
                ref={intro}>
                <span className="link-underlined-container">
                  <span className="link-underlined" data-text="Intro">
                    <svg viewBox="0 0 24 25" className="icon-arrow">
                      <use href={arrow + '#arrow-icon'}></use>
                    </svg>
                    <span className="underline-text">Intro</span>
                  </span>
                </span>
              </Link>
            </li>
            <li className="nav-item">
              <Link
                onClick={() => setToggle(false)}
                to={`${Routes.Missions}/1`}
                className={getClasses({
                  'nav-link': true,
                  'menu-item': true,
                  active: location.pathname === `${Routes.Missions}/1`,
                })}
                ref={mission1}>
                <span className="link-underlined-container">
                  <span className="link-underlined" data-text="Missione 1">
                    <svg viewBox="0 0 24 25" className="icon-arrow">
                      <use href={arrow + '#arrow-icon'}></use>
                    </svg>
                    <span className="underline-text">Missione 1</span>
                  </span>
                </span>
              </Link>
            </li>
            <li
              className="nav-item"
              data-bs-toggle="tooltip"
              data-bs-placement="right"
              title="Completa la missione 1 per sbloccare">
              <Link
                onClick={() => setToggle(false)}
                to={`${Routes.Missions}/2`}
                className={getClasses({
                  'nav-link': true,
                  disabled: !result.completed?.includes('1'),
                  'menu-item': true,
                  active: location.pathname === `${Routes.Missions}/2`,
                })}
                ref={mission2}>
                <span className="link-underlined-container">
                  <span
                    className={getClasses({ 'link-underlined': result.completed?.includes('1') })}
                    data-text="Missione 2">
                    <svg viewBox="0 0 24 25" className="icon-arrow">
                      <use href={arrow + '#arrow-icon'}></use>
                    </svg>
                    <span className="underline-text">Missione 2</span>
                    {!result.completed?.includes('1') && (
                      <>
                        <svg viewBox="0 0 24 25" className="icon-lock">
                          <use href={lock + '#lock-icon'}></use>
                        </svg>
                        <span className="nav-link-subtitle">Completa la missione 1</span>
                      </>
                    )}
                  </span>
                </span>
              </Link>
            </li>
            <li
              className="nav-item"
              data-bs-toggle="tooltip"
              data-bs-placement="right"
              title="Completa la missione 2 per sbloccare">
              <Link
                onClick={() => setToggle(false)}
                to={`${Routes.Missions}/3`}
                className={getClasses({
                  'nav-link': true,
                  disabled: !result.completed?.includes('2'),
                  'menu-item': true,
                  active: location.pathname === `${Routes.Missions}/3`,
                })}
                ref={mission3}>
                <span className="link-underlined-container">
                  <span
                    className={getClasses({ 'link-underlined': result.completed?.includes('2') })}
                    data-text="Missione 3">
                    <svg viewBox="0 0 24 25" className="icon-arrow">
                      <use href={arrow + '#arrow-icon'}></use>
                    </svg>
                    <span className="underline-text">Missione 3</span>
                    {!result.completed?.includes('2') && (
                      <>
                        <svg viewBox="0 0 24 25" className="icon-lock">
                          <use href={lock + '#lock-icon'}></use>
                        </svg>
                        <span className="nav-link-subtitle">Completa la missione 2</span>
                      </>
                    )}
                  </span>
                </span>
              </Link>
            </li>
            <li className="nav-item">
              <Link
                onClick={() => setToggle(false)}
                to={Routes.About}
                className={getClasses({
                  'nav-link': true,
                  'menu-item': true,
                  active: location.pathname === '/about',
                })}
                ref={about}>
                <span className="link-underlined-container">
                  <div className="link-underlined" data-text="About">
                    <svg viewBox="0 0 24 25" className="icon-arrow">
                      <use href={arrow + '#arrow-icon'}></use>
                    </svg>
                    <span className="underline-text">About</span>
                  </div>
                </span>
              </Link>
            </li>
            {isDesktop && (
              <li className="nav-item audio-btn-container">
                <AudioButton status={status} onClick={audioToggle} />
              </li>
            )}
          </ul>
          <TextButton text="Unisciti al cambiamento" onClick={goToSubscription} />
        </div>
      </div>
      {!isDesktop && (
        <div className="audio-btn-container">
          <AudioButton status={status} onClick={audioToggle} />
        </div>
      )}
    </nav>
  );
};

export default Navbar;

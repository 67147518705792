import './AudioButton.scss';
import React from 'react';
import getClasses from '../../utils/getClasses';
import AudioButtonProps from './AudioButton.models';
import { useRef } from 'react';
import useFocus from '../../hooks/useFocus';

const AudioButton = ({ status, onClick }: AudioButtonProps): JSX.Element => {
  const ref = useRef<HTMLButtonElement>(null);
  useFocus(ref, 'with-keyboard');

  return (
    <button ref={ref} className="btn audio-btn" onClick={onClick}>
      <div className="waves">
        <div className={getClasses({ bar: true, infinite: status === 'played', disabled: status !== 'played' })} />
        <div className={getClasses({ bar: true, infinite: status === 'played', disabled: status !== 'played' })} />
        <div className={getClasses({ bar: true, infinite: status === 'played', disabled: status !== 'played' })} />
      </div>
    </button>
  );
};

export default AudioButton;

import React, { useEffect } from 'react';

const useFocus = <T extends HTMLElement = HTMLElement>(ref: React.MutableRefObject<T> | React.RefObject<T>, className: string): void => {
  const mouseDownHandler = () => {
    ref.current?.classList.remove(className);
  };
  const keyDownHandler = () => {
    ref.current?.classList.add(className);
  };

  useEffect(() => {
    ref.current?.addEventListener('mousedown', mouseDownHandler);
    ref.current?.addEventListener('keydown', keyDownHandler);
    return () => {
      ref.current?.removeEventListener('mousedown', mouseDownHandler);
      ref.current?.removeEventListener('keydown', keyDownHandler);
    };
  }, [ref]);
};

export default useFocus;

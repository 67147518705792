import React from 'react';
import './style.scss';
import IconLinkButton from '../IconLinkButton/IconLinkButton';
import download from '../../assets/icons/download.svg';
import close from '../../assets/icons/close.svg';
import ShareDialogProps from './ShareDialog.models';
import { Dialog, DialogActions, DialogContent, DialogTitle, makeStyles } from '@material-ui/core';
import { convertToRem } from '../../utils/styles.utils';
import colors from '../../assets/scss/colors.module.scss';
import { TransitionProps } from '@material-ui/core/transitions/transition';
import Slide from '@material-ui/core/Slide';

const DialogTransition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children?: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ShareDialog = ({ image, open, onClose } : ShareDialogProps): JSX.Element => {
  
  const classes = useStyles();

  return (
    <Dialog
      TransitionComponent={DialogTransition}
      open={open}
      onClose={onClose}
      classes={{
        paper: classes.paper,
        scrollPaper: classes.scrollPaper
      }}
      className="share-dialog-container"
    >
      <DialogTitle
        disableTypography
        classes={{
          root: classes.dialogTitle
        }}
      >
        <h1 className="header-2">Condividi</h1>

        <button type="button" className="btn close-btn" onClick={(e)=>onClose && onClose(e, 'escapeKeyDown')}>
          <svg viewBox="0 0 32 32" className="svg-icon">
            <use xlinkHref={close} href={`${close}#close`}></use>
          </svg>
        </button>
      </DialogTitle>
      <DialogContent classes={{
        root: classes.dialogContent
      }}>
        <img src={image} className="shared-image" />
      </DialogContent>
      <DialogActions classes={{
        root: classes.dialogActions
      }}>
        <IconLinkButton icon={`${download}#download`} download href={image} />
      </DialogActions>
    </Dialog>
  );
};

const useStyles = makeStyles({
  scrollPaper: {
    alignItems: 'flex-end'
  },
  paper: {
    maxWidth: '100%',
    margin: 0,
    borderRadius: 0,
    borderTopLeftRadius: convertToRem(16),
    borderTopRightRadius: convertToRem(16),
  },
  dialogTitle: {
    padding: '32px 32px 20px',
    color: colors.flatDarkBlue,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  dialogContent: {
    padding: '20px 32px',
  },
  dialogActions: {
    padding: '20px 32px 54px',
    justifyContent: 'center'
  }
});

export default ShareDialog;


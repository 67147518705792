import React, { useEffect, useLayoutEffect } from 'react';
import styles from './FirstMissionEnd.module.scss';
import { CommonInnerNativagionProps } from '../../../../hooks/useInnerNavigator';
import { useMediaQuery } from 'react-responsive';
import MissionCounterParagraph from '../../../../missions/components/MissionCounterParagraph/MissionCounterParagraph';
import getClasses from '../../../../utils/getClasses';
import RoundButton from '../../../../components/RoundButton/RoundButton';
import MissionIllustrations from '../../../../missions/components/MissionIllustrations/MissionIllustrations';
import useIllustrationsAsset from '../../../../hooks/useIllustrationsAsset';
import gsap from 'gsap';
import { fadeIn, fadeOut } from '../../../../utils/fadeElements';
import EllipsedText from '../../../../components/EllipsedText/EllipsedText';

const listTitle = 'Ecco cosa puoi fare';

const list = [
  'Fai riparare i tuoi abiti o scambiali.',
  'Acquista in vintage & second hand shop.',
  'Scegli brand che attuano processi rispettosi dell\'ambiente.',
  'Compra capi realizzati in canapa e accessori in poliestere sostenibile.',
];

const FirstMissionEnd: React.FC<Pick<CommonInnerNativagionProps, 'onComplete'>> = ({ onComplete }) => {
  const hiddenMissionCounter = useMediaQuery({ minDeviceWidth: 768 });
  const thumbnailMissionIllustrations = useMediaQuery({ minDeviceWidth: 1024 });
  const targetsToFade = [
    `.${styles['mission-counter']}`,
    `.${styles.title}`,
    `.${styles.content}`,
    `.${styles['btn-container']}`
  ];
  const illustrations = useIllustrationsAsset('clothes');

  useLayoutEffect(() => {
    gsap.to(`.${styles['btn-container']}`, { opacity: 1, duration: 0.7, delay: 0.1 });
    const img = gsap.to('.end-mission-container .image', {
      opacity: 1,
      duration: 0.7,
      delay: 0.3,
      stagger: (index) => [0.3, 0.4][index],
    });
    const fade = fadeIn(targetsToFade);

    return () => {
      img.kill();
      fade.kill();
    };
  }, []);

  const onTap = () => {
    gsap.to(`.${styles['btn-container']}`, { opacity: 0, duration: 0.7, delay: 0.8 }).then(onComplete);
    gsap.to('.end-mission-container .image', {
      opacity: 0,
      duration: 0.7,
      delay: 0.4,
      stagger: (index) => [0.3, 0.4][index] 
    });
    fadeOut(targetsToFade);
  };

  return (
    <div className={`primary-container end-mission-container ${styles['custom-container']}`}>
      <div className={styles.header}>
        <MissionIllustrations hidden illustrations={illustrations} thumbnail={thumbnailMissionIllustrations} />
        {/* <MissionCounterParagraph
          className={getClasses({ [styles['mission-counter']]: true, [styles.hidden]: hiddenMissionCounter })}
        /> */}
        <div className={styles.title}>
          <EllipsedText type="mission-end-title-container">
            <h1 className="title-extra">Missione compiuta!</h1>
          </EllipsedText>
        </div>
      </div>
      <div className={styles.content}>
        <p className={`paragraph ${styles.description}`}>
          I <span className="paragraph-bold">vestiti non sostenibili</span>, abbandonati nell&apos;armadio o gettati via
          dopo pochi utilizzi, rappresentano <span className="paragraph-bold">15,1 milioni di tonnellate</span> di
          rifiuti tessili, di cui l’85% è finito nelle discariche. Inoltre, la maggior parte di questi tessuti{' '}
          <span className="paragraph-bold">non</span> è nemmeno <span className="paragraph-bold">biodegradabile</span>.
          Questo significa che <span className="paragraph-bold">per essere smaltito</span> completamente è necessario un
          periodo di circa <span className="paragraph-bold">200 anni</span>.
        </p>
        <div className={styles.list}>
          <h2 className={getClasses({ [styles['list-title']]: true, 'header-3': true })}>{listTitle}</h2>
          <ul className="list-container">
            {list.map((element, index) => (
              <li key={`cose-da-fare-${index}`}>{element}</li>
            ))}
          </ul>
        </div>
      </div>
      <div className={styles['btn-container']}>
        <RoundButton onClick={onTap} />
      </div>
    </div>
  );
};

export default FirstMissionEnd;

import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import './IntroPage.scss';
import Preloader from './components/Preloader/Preloader';
import IntroStepOne from './components/IntroStepOne/IntroStepOne';
import IntroStepTwo from './components/IntroStepTwo/IntroStepTwo';
import ScrollTrigger from 'gsap/ScrollTrigger';
import ScrollToPlugin from 'gsap/ScrollToPlugin';
import useAudio from '../../hooks/useAudio';
import { useHistory } from 'react-router';
import { Routes } from '../../App';
import gsap, { Back } from 'gsap';
import useWindowDimensions from 'src/hooks/useWindowsDimension';
import IntroStepTree from './components/IntroStepTree/IntroStepTree';
import { convertToRem } from 'src/utils/styles.utils';
import { useMediaQuery } from 'react-responsive';
import IntroController from './components/IntroController/IntroController';
import colors from '../../assets/scss/colors.module.scss';

gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(ScrollToPlugin);

const PRELOADER_FLAG = '_preloader';

const IntroPage: React.FC<ReturnType<typeof useAudio>> = ({ play }) => {
  const initTimeline = useRef<gsap.core.Timeline | null>(null);
  const history = useHistory();
  const { height } = useWindowDimensions();
  const introStepOneRef = useRef<HTMLDivElement>(null);
  const introStepTwoRef = useRef<HTMLDivElement>(null);
  const preloaderStatus = sessionStorage.getItem(PRELOADER_FLAG) === 'off' ? 'off' : 'on';
  const [innerNavigator, setInnerNavigator] = useState<'preloader' | 1 | 2 | 3>(
    preloaderStatus === 'off' ? 1 : 'preloader',
  );
  const isDesktop = useMediaQuery({ minDeviceWidth: 1200 });

  useEffect(() => {
    preloaderStatus === 'off' && runIntro();
  }, [preloaderStatus]);

  useEffect(() => {
    if (innerNavigator === 'preloader') {
      gsap.set('body', { overflow: 'hidden' });
    }
  }, [innerNavigator]);

  useLayoutEffect(() => {
    const sections: HTMLElement[] = gsap.utils.toArray([
      '.intro-step-one-container',
      '.intro-step-two-container',
      '.intro-step-tree-container',
    ]);
    let currentSection = sections[0];
    const setSection = (newSection: HTMLElement) => {
      setInnerNavigator((sections.findIndex((section) => section.className === newSection.className) + 1) as typeof innerNavigator);
      if (newSection !== currentSection) {
        gsap.to(currentSection, {
          autoAlpha: 0,
        });
        gsap.to(newSection, {
          autoAlpha: 1,
        });
        currentSection = newSection;
      }
    };

    initTimeline.current = gsap.timeline({ stagger: 0.4 });

    const getAnimationBySection = (index: Omit<typeof innerNavigator, 'preloader'>) => {
      const fade = (mainClass: string) => {
        return gsap.fromTo(
          [`${mainClass} .description`, `${mainClass} .description-paragraph`],
          {
            autoAlpha: 0,
            y: '100%',
          },
          {
            y: 0,
            autoAlpha: 1,
            paused: true,
          },
        );
      };
      switch (index) {
        case 2:
          return fade('.intro-step-two-container');
        case 3:
          return fade('.intro-step-tree-container');
      }
    };

    gsap.set('.intro-container', { height: sections.length * 100 + '%' });

    const tl = gsap
      .timeline({
        scrollTrigger: {
          trigger: '.intro-step-one-container',
          scrub: 5,
          start: 'top top',
          end: '+=5% 0',
          invalidateOnRefresh: true,
        },
      })
      .to(
        '.content-label',
        {
          opacity: 0,
          duration: 1,
        },
        1,
      )
      .to(
        '.intro-step-one-container .text-path',
        {
          attr: { startOffset: '64%', textLength: 200 },
          duration: 2,
          opacity: 0,
          ease: Back.easeOut,
        },
        2,
      )
      .to(
        '.content',
        {
          height: '100vh',
          width: '100vw',
          position: 'fixed',
          duration: 2,
        },
        2.5,
      )
      .to(
        '.door-mask',
        {
          borderRadius: 0,
          duration: 2,
        },
        2.5,
      )
      .to(
        ['.link-container', '.btn-container', '.nav-link', '.navbar-brand', '.audio-btn'],
        { color: '#fff', duration: 2 },
        2.5,
      )
      .to('.intro-controller .scroller-button', { color: colors.flatLightPurple, duration: 2 }, 2.5)
      .to(
        '.icon-bar',
        { backgroundColor: '#fff', duration: 2 },
        2.5
      )
      .to(['.audio-btn'], { borderColor: '#fff', duration: 2 }, 2.5)
      .to(['.bar'], { background: '#fff', duration: 2 }, 2.5)
      .to(
        '.earth',
        {
          width: convertToRem(334),
          duration: 2,
        },
        2.5,
      )
      .to(
        '.moon',
        {
          width: convertToRem(83),
          duration: 2,
        },
        2.5,
      )
      .to(['.earth', '.mars'], { translateY: '-15%', duration: 4 }, 3)
      .to('.moon', { translateY: '-30%', duration: 4 }, 3)
      .to('.planet', { scale: 0.8, duration: 4 }, 3)
      .to('.description', { translateY: 0, opacity: 1, duration: 5 }, 3)
      .to('.description-paragraph', { translateY: 0, opacity: 1, duration: 2 }, 7)
      .to('.round-button', { translateY: 0, opacity: 1, duration: 2 }, 7.5);

    const triggers = sections.map((section, i) => {
      const animation = getAnimationBySection(i + 1);
      return ScrollTrigger.create({
        // use dynamic scroll positions based on the window height (offset by half to make it feel natural)
        start: () => (i - 0.5) * innerHeight,
        end: () => (i + 0.5) * innerHeight,
        // when a new section activates (from either direction), set the section accordinglyl.
        onToggle: (self) => {
          const fadeInController = () => gsap.to(['.scroller-button', '.skip-container'], { opacity: 1 });
          const fadeOutController = () => gsap.to(['.scroller-button', '.skip-container'], { opacity: 0 });
          if (self.isActive) {
            setSection(section);
            i === 2 && fadeOutController();
            i === 1 && fadeInController();
          }
          
        },
        onEnter: () => animation?.restart(),
        invalidateOnRefresh: true,
        markers: {
          startColor: 'transparent',
          endColor: 'transparent',
        },
      });
    });

    if (isDesktop) {
      tl.to(
        '.mars',
        {
          width: convertToRem(155),
          duration: 2,
        },
        2.5,
      );
    }

    return () => {
      tl.time(0).kill();
      triggers.forEach((trigger) => trigger.kill());
      gsap.set(['.intro-container', 'body'], { clearProps: 'all' });
    };
  }, []);

  const runIntro = () => {
    if (preloaderStatus === 'on') {
      sessionStorage.setItem(PRELOADER_FLAG, 'off');
      setInnerNavigator(1);
      initTimeline.current?.set('body', { clearProps: 'overflow' });
    }
    initTimeline.current?.fromTo(
      '.door-mask',
      {
        opacity: 0,
      },
      {
        opacity: 1,
        duration: 0.2,
      })
    .to('.intro-step-one-container .text-path', {
      attr: { startOffset: '24%', textLength: 415 },
      duration: 1,
      opacity: 1,
      ease: Back.easeInOut,
    }, 0.01)
    .fromTo(
      '.content-label',
      {
        opacity: 0,
      },
      {
        opacity: 1,
        duration: 0.8,
      }, 0.5);
   /*  gsap.to('.intro-step-one-container', {
      opacity: 1,
      duration: 0.5,
      ease: Back.easeIn,
      onStart: () => {
       
      },
    }); */
  };

  const onScroll = useCallback(
    () => {
      window.scrollTo({
        top: innerHeight * (innerNavigator as number) - 1
      });
    },
    [innerNavigator],
  );

  return (
    <>
      {preloaderStatus === 'on' && <Preloader play={play} onComplete={runIntro} />}
      <div className="cover-background"/>
      <div className="intro-container">
        <div ref={introStepOneRef} style={{ height }} className="intro-step-one-container">
          <IntroStepOne />
        </div>
        <div ref={introStepTwoRef} style={{ height }} className="intro-step-two-container">
          <IntroStepTwo />
        </div>
        <div style={{ height }} className="intro-step-tree-container">
          <IntroStepTree
            onComplete={() =>
              gsap
                .timeline()
                .to(
                  '.intro-step-tree-container .content',
                  {
                    autoAlpha: 0,
                  },
                  0.5,
                )
                .to('.final-step-container', { autoAlpha: 1 }, 0.5)
                .to('.final-step-container .background-left', { x: -(innerHeight / 2), opacity: 0, duration: 0.5 }, 2)
                .to('.final-step-container .background-right', { x: innerHeight, opacity: 0, duration: 0.5 }, 2)
                .then(() => history.push(Routes.Missions))
            }
          />

          <div style={{ height }} className="final-step-container">
            <div className="background-cover background-right" />
            <div className="background-cover background-left" />
          </div>
        </div>
        <IntroController disableScroller={!isDesktop} onClick={onScroll} />
      </div>
    </>
  );
};

export default IntroPage;

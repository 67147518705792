import './IntroStepTree.scss';
import React from 'react';
import Lottie from 'react-lottie';
import EllipsedText from 'src/components/EllipsedText/EllipsedText';
import Background from '../../../../assets/lotties/blatte_bg.json';
import RoundButton from 'src/components/RoundButton/RoundButton';
import colors from '../../../../assets/scss/colors.module.scss';
import { CommonInnerNativagionProps } from 'src/hooks/useInnerNavigator';

const IntroStepTree: React.FC<Pick<CommonInnerNativagionProps, 'onComplete'>> = ({ onComplete }) => {
  return (
    <div className="content">
      <div className="cover">
        <Lottie
          options={{
            animationData: Background,
            loop: true,
            autoplay: true,
            rendererSettings: { preserveAspectRatio: 'xMidYMid slice' },
          }}
        />
      </div>
      <div className="description">
        <EllipsedText type="intro-step-one">
          <h1 className="header-2 label">L’eroe</h1>
        </EllipsedText>
        <p className="paragraph description-paragraph">
          Il solo modo di cambiare il destino del pianeta è intervenire sul passato. Torna indietro nel tempo e supera
          le prove per rimediare agli errori.
        </p>
        <RoundButton onClick={onComplete} color={colors.flatLightPurple} />
      </div>
    </div>
  );
};

export default IntroStepTree;

import './SubscriptionPage.scss';
import React, { useLayoutEffect } from 'react';
import EllipsedText from '../../components/EllipsedText/EllipsedText';
import InputField from '../../components/InputField/InputField';
import useForm from '../../hooks/useForm';
import TextButton from '../../components/TextButton/TextButton';
import ArrowRight from '../../assets/icons/arrow-right.svg';
import CloudBackground from '../../assets/images/cloud.svg';
import FormBackground from '../../assets/lotties/form-background.json';
import IconLink from '../../components/IconLink/IconLink';
import { StylesProvider } from '@material-ui/styles';
import CheckBox from '../../components/CheckBox/CheckBox';
import { useHistory } from 'react-router';
import { Routes } from '../../App';
import Footer from '../../components/Footer/Footer';
import useMissionsHandler from '../../hooks/useMissionsHandler';
import Lottie from 'react-lottie';
import { fadeIn } from 'src/utils/fadeElements';
import ScrollTrigger from 'gsap/ScrollTrigger';
import gsap from 'gsap';

interface SubscriptionPageForm {
  name: string;
  surname: string;
  email: string;
  authorization: boolean;
  privacy: boolean;
  phone?: string;
}

const errorMessages = {
  required: 'Campo obbligatorio',
  invalidName: 'Il nome non è valido',
  invalidSurname: 'Il cognome non è valido',
  invalidEmail: 'L\'indirizzo email non è valido',
  invalidPhone: 'Il numero non è valido',
};

const authorizationPolicyLink = 'https://www.manitese.it/privacy-cookies-policy';
const privacyPolicyLink = 'https://www.manitese.it/privacy-cookies-policy#cookies';

const isInvalidString = (value: string) => !/^[a-zA-Z\s]+$/.test(value.trim());
const isInvalidEmail = (value: string) =>
  !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    value,
  );

gsap.registerPlugin(ScrollTrigger);

const SubscriptionPage: React.FC = () => {
  const history = useHistory();
  const { isCompleted } = useMissionsHandler();
  const title = isCompleted ? 'Hai salvato il pianeta' : 'Puoi salvare il pianeta!';
  const description = isCompleted
    ? 'È bello sentirsi degli eroi, vero? Puoi esserlo anche nella realtà. Solo con il contributo di ognuno di noi è possibile cambiare le cose.'
    : 'Un mondo migliore è possibile solo grazie al contributo di tutti. Se ti stai chiedendo da dove cominciare noi possiamo suggerirti di metterti in gioco facendo il primo passo.';
  const subdescription = 'Mani Tese si batte ogni giorno per salvaguardare l\'ambiente. Plasmiamo insieme il futuro.';
  const targetsToFade = [
    '.subscription-page-container .subscription-title-container',
    '.subscription-page-container .paragraph',
    '.subscription-page-container .header-3',
    '.subscription-page-container .input-field',
    '.subscription-page-container .checkbox',
    '.subscription-page-container .form-btn-container',
    '.subscription-page-container .link-wrapper',
  ];

  useLayoutEffect(() => {
    const anim = fadeIn(targetsToFade);
    const trigger = ScrollTrigger.create({
      trigger: '.custom-container',
      start: 'top top',
      end: 'bottom bottom',
      onLeave: () => {
        gsap.to('.audio-btn', { opacity: 0 });
      },
      onEnterBack: () => {
        gsap.to('.audio-btn', { opacity: 1 });
      },
      invalidateOnRefresh: true,
    });
    return () => {
      trigger.kill();
      anim.time(0).kill();
    };
  }, []);

  const { form, setForm, submit, errors } = useForm<SubscriptionPageForm>(
    {
      name: '',
      surname: '',
      email: '',
      authorization: false,
      privacy: false,
    },
    {
      name: {
        required: errorMessages.required,
        custom: (value) => {
          if (isInvalidString(value)) return errorMessages.invalidName;
        },
      },
      surname: {
        required: errorMessages.required,
        custom: (value) => {
          if (isInvalidString(value)) return errorMessages.invalidSurname;
        },
      },
      email: {
        required: errorMessages.required,
        custom: (value) => {
          if (isInvalidEmail(value)) return errorMessages.invalidEmail;
        },
      },
      authorization: {
        custom: (value) => {
          if (!value) {
            return 'error';
          }
        },
      },
      privacy: {
        custom: (value) => {
          if (!value) {
            return 'error';
          }
        },
      },
      phone: {
        custom: (value) => {
          if (!value) return undefined;
          if (!/[0-9]|\./.test(value)) {
            return errorMessages.invalidPhone;
          }
        },
      },
    },
  );

  const onSubmit = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.preventDefault();
    submit(() => {
      try {
        fetch('https://vestilfuturo.manitese.it/be/index.php/wp-json/api/v1/form', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            name: form?.name?.trim(),
            surname: form.surname,
            email: form.email,
            terms: form.authorization,
            privacy: form.privacy,
            phone: form?.phone,
            marketingFlag: isCompleted,
          }),
        });
      } catch (err) {
        return null;
      } finally {
        history.replace(Routes['Thank-You'], { previousRoute: history.location.pathname });
      }
    });
  };

  return (
    <>
    <div className="subscription-page-container">
      <StylesProvider injectFirst>
        <section className="section-parallax">
          <Lottie
            speed={0.5}
            options={{
              animationData: FormBackground,
              loop: true,
              autoplay: true,
              rendererSettings: { preserveAspectRatio: 'xMidYMin slice', className: 'cover' },
            }}
          />
        </section>
        <section className="section-content">
          <img src={CloudBackground} className="cloud-background" />
          <div className="container-fluid custom-container">
            <div className="title">
              <EllipsedText type="subscription-title-container">
                <h1 className="header-2">{title}</h1>
              </EllipsedText>
            </div>
            <div className="row description-row">
              <div className="col-md-8 col-lg-10 col-xl-8">
                <p className="paragraph paragraph-description">{description}</p>
                <p className="paragraph sub-description">{subdescription}</p>
                <h2 className="header-3">Unisciti al cambiamento</h2>
                <p className="paragraph">Compila il form</p>
              </div>
            </div>
            <form noValidate autoComplete="off" className="form row">
              <div className="col-md-8 col-lg-10 col-xl-8">
                <div className="row">
                  <div className="col-lg-6">
                    <InputField
                      id="name-field"
                      label="Nome"
                      placeholder="Il tuo nome"
                      error={Boolean(errors.name)}
                      helperText={errors.name}
                      onChange={(e) => setForm('name', e.target.value)}
                      required
                      className="input-field"
                    />
                  </div>
                  <div className="col-lg-6">
                    <InputField
                      id="surname-field"
                      label="Cognome"
                      placeholder="Il tuo cognome"
                      error={Boolean(errors.surname)}
                      helperText={errors.surname}
                      onChange={(e) => setForm('surname', e.target.value)}
                      required
                      className="input-field"
                    />
                  </div>
                  <div className="col-lg-6">
                    <InputField
                      id="email-field"
                      label="Email"
                      placeholder="La tua email"
                      error={Boolean(errors.email)}
                      helperText={errors.email}
                      onChange={(e) => setForm('email', e.target.value)}
                      required
                      className="input-field"
                    />
                  </div>
                  <div className="col-lg-6">
                    <InputField
                      id="phone-field"
                      label="Cellulare"
                      type="tel"
                      placeholder="Il tuo cellulare"
                      error={Boolean(errors.phone)}
                      helperText={errors.phone}
                      onChange={(e) => setForm('phone', e.target.value)}
                      className="input-field"
                    />
                  </div>
                  <div className="col-12">
                    <div className="checkbox">
                      <CheckBox
                        label={
                          <p className="paragraph checkbox-paragraph">
                            Autorizzo il trattamento dei dati come da{' '}
                            <a className="paragraph-bold link" href={authorizationPolicyLink}>
                              informativa privacy
                            </a>
                            , ai sensi del Regolamento UE 2016/67*
                          </p>
                        }
                        error={Boolean(errors.authorization)}
                        onChange={(checked) => setForm('authorization', checked)}
                      />
                    </div>
                    <div className="checkbox">
                      <CheckBox
                        label={
                          <p className="paragraph checkbox-paragraph">
                            Ho letto e accetto{' '}
                            <a className="paragraph-bold link" href={authorizationPolicyLink}>
                              Privacy policy
                            </a>{' '}
                            e{' '}
                            <a className="paragraph-bold link" href={privacyPolicyLink}>
                              Cookie policy
                            </a>
                          </p>
                        }
                        error={Boolean(errors.privacy)}
                        onChange={(checked) => setForm('privacy', checked)}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="form-btn-container">
                <TextButton text="Invia" onClick={onSubmit} />
              </div>
            </form>
            <div className="about-us-section">
              <h3 className="header-3">Vuoi sapere di più?</h3>
              <p className="paragraph">Leggi di più sul progetto</p>
              <IconLink text="About us" icon={`${ArrowRight}#arrow-right-icon`} to={Routes.About} />
            </div>
          </div>
        </section>
      </StylesProvider>
    </div>
    <Footer />
    </>
  );
};

export default SubscriptionPage;

import React, { useEffect } from 'react';
import './assets/scss/global.scss';
import useMissionsHandler from './hooks/useMissionsHandler';
import { BrowserRouter, Redirect, Route, Switch, useLocation } from 'react-router-dom';
import IntroPage from './pages/IntroPage/IntroPage';
import FirstMissionPage from './pages/FirstMissionPage/FirstMissionPage';
import SecondMissionPage from './pages/SecondMissionPage/SecondMissionPage';
import ThirdMissionPage from './pages/ThirdMissionPage/ThirdMissionPage';
import ThankYouPage from './pages/ThankYouPage/ThankYouPage';
import SubscriptionPage from './pages/SubscriptionPage/SubscriptionPage';
import AboutPage from './pages/About/AboutPage';
import Navbar from './components/Navbar/Navbar';
import useAudio from './hooks/useAudio';
import mockedAudio from './assets/audio/FD_TEXTURE_(LOOP)_bd.mp3';
import StartMissionPage from './pages/StartMissionPage/StartMissionPage';
import NotFoundPage from './pages/NotFound/NotFoundPage';

const audio = new Audio(mockedAudio);

export enum Routes {
  'Root' = '/',
  'Missions' = '/missions',
  'Subscription' = '/subscription',
  'Thank-You' = '/thank-you',
  'About' = '/about',
}
const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

const App: React.FC = () => {
  const { isCompletedMissionByID } = useMissionsHandler();
  const audioProps = useAudio(audio);

  const renderProtectedRoute = (Component: React.FC, trigger: boolean) => {
    return trigger ? <Component /> : <Redirect to="/" />;
  };

  return (
    <BrowserRouter>
      <ScrollToTop />
      <Navbar {...audioProps} />
      <Switch>
        <Route exact path={Routes.Root} render={() => <IntroPage {...audioProps} />} />
        <Route exact path={`${Routes.Missions}`} component={StartMissionPage} />
        <Route exact path={`${Routes.Missions}/1`} component={FirstMissionPage} />
        <Route
          exact
          path={`${Routes.Missions}/2`}
          render={() => renderProtectedRoute(SecondMissionPage, isCompletedMissionByID('1'))}
        />
        <Route
          exact
          path={`${Routes.Missions}/3`}
          render={() => renderProtectedRoute(ThirdMissionPage, isCompletedMissionByID('2'))}
        />
        <Route exact path={Routes.About} component={AboutPage} />
        <Route exact path={Routes.Subscription} component={SubscriptionPage} />
        <Route
          exact
          path={Routes['Thank-You']}
          render={({ location }) =>
            renderProtectedRoute(ThankYouPage, (location as any)?.state?.previousRoute === Routes.Subscription)
          }
        />
        <Route path="*" component={NotFoundPage} />
      </Switch>
    </BrowserRouter>
  );
};

export default App;

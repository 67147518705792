import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import useMissionsHandler from '../../hooks/useMissionsHandler';
import useInnerNavigator from '../../hooks/useInnerNavigator';
import MemoryMission from '../../missions/MemoryMission/MemoryMission';
import FirstMissionEnd from './components/FirstMissionEnd/FirstMissionEnd';
import MissionStart from '../../missions/components/MissionStart/MissionStart';
import useIllustrationsAsset from '../../hooks/useIllustrationsAsset';

const FirstMissionPage: React.FC = () => {
  const history = useHistory();
  const [innerNavigator, setInnerNavigator] = useInnerNavigator();
  const { onComplete } = useMissionsHandler();
  const illustrations = useIllustrationsAsset('clothes');

  const title = 'Liberati del superfluo';
  const description =
    'Quanti vestiti hai comprato senza mai indossarli per poi buttarli via dopo poco tempo? Ognuno di quei capi ha contribuito a inquinare la Terra e ad alimentare una discarica in continua espansione.';
  const endDescription = 'Accoppia le card per eliminare i vestiti non sostenibili dal tuo armadio.';

  useEffect(() => {
    /* TODO: quando diventano della pagine separate eliminare questo codice */
    window.scrollTo(0, 0);
  }, [innerNavigator]);

  return (
    <>
      {innerNavigator === 'start' && (
        <MissionStart
          title={title}
          description={description}
          endDescription={endDescription}
          illustrations={illustrations}
          onComplete={() => setInnerNavigator('in-progress')}
        />
      )}
      {innerNavigator === 'in-progress' && (
        <MemoryMission
          goBack={() => setInnerNavigator('start')}
          onComplete={() => {
            onComplete(history.location.pathname.split('/')[2]);
            setInnerNavigator('completed');
          }}
        />
      )}
      {innerNavigator === 'completed' && <FirstMissionEnd onComplete={() => history.replace('2')} />}
    </>
  );
};

export default FirstMissionPage;

import React, { useLayoutEffect, useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation } from 'swiper';
import 'swiper/swiper.min.css';
import 'swiper/components/navigation/navigation.min.css';
import './ThankYouPage.scss';
import colors from '../../assets/scss/colors.module.scss';
import EllipsedText from '../../components/EllipsedText/EllipsedText';
import IconLink from '../../components/IconLink/IconLink';
import TextButton from '../../components/TextButton/TextButton';
import ArrowRight from '../../assets/icons/arrow-right.svg';
import ArrowLeft from '../../assets/icons/arrow.svg';
import Social1 from '../../assets/images/manitese-social-01.jpeg';
import Social2 from '../../assets/images/manitese-social-02.jpeg';
import Social3 from '../../assets/images/manitese-social-03.jpeg';
import Social4 from '../../assets/images/manitese-social-04.jpeg';
import Social5 from '../../assets/images/manitese-social-05.jpeg';
import Social6 from '../../assets/images/manitese-social-06.jpeg';
import Social7 from '../../assets/images/manitese-social-07.jpeg';
import Social8 from '../../assets/images/manitese-social-08.jpeg';
import IconButton from '../../components/IconButton/IconButton';
import { Routes } from '../../App';
import ShareDialog from '../../components/ShareDialog/ShareDialog';
import { fadeIn } from 'src/utils/fadeElements';

SwiperCore.use([Navigation]);
const imageCards = [Social1, Social2, Social3, Social4, Social5, Social6, Social7, Social8];

const ThankYouPage: React.FC = () => {
  const history = useHistory();

  const restartGame = () => history.push(`${Routes.Missions}/1`);

  const [startAnimationShareDialog, setStartAnimationShareDialog] = useState<boolean>(false);
  const [openShareDialog, setOpenShareDialog] = useState<string | undefined>(undefined);
  

  const openDialog = (image: string) => {
    setStartAnimationShareDialog(true);
    setOpenShareDialog(image);
  };

  const closeDialog = () => {
    setStartAnimationShareDialog(false);
  };

  useEffect(() =>{
    if (!startAnimationShareDialog) {
      
      const timeout = setTimeout(() => {
        setOpenShareDialog(undefined);
      }, 500);
    
      return () => {
        clearTimeout(timeout);
      };
    }
  }, [startAnimationShareDialog]);

  const targetsToFade = [
    '.thank-you-page-container .title-extra',
    '.thank-you-page-container .paragraph',
    '.thank-you-page-container .arrow-icons',
    '.thank-you-page-container .choose-title',
    '.thank-you-page-container .navigation-buttons',
    '.thank-you-page-container .slider-container',
    '.thank-you-page-container .play-again',
  ];

  useLayoutEffect(() => {
    const anim = fadeIn(targetsToFade);
    return () => {
      anim.time(0).kill();
    };
  }, []);

  return (
    <div className="thank-you-page-container">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-10 col-lg-7 offset-lg-1">
            <div className="thank-you-container-info">
              <EllipsedText>
                <h1 className="title-extra">Grazie!</h1>
              </EllipsedText>
              <p className="paragraph thank-paragraph">Oggi è un gran giorno per tutti noi, hai fatto il primo passo verso un futuro migliore. Ti ricontatteremo presto.</p>
              <div className="arrow-icons">
                {/* <IconLink text="Approfondisci" color={colors.flatPurple} icon={`${ArrowRight}#arrow-right-icon`} /> */}
                <IconLink
                  text="Gioca ancora"
                  color={colors.flatPurple}
                  icon={`${ArrowRight}#arrow-right-icon`}
                  onClick={restartGame}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="row row-full-width">
          <div className="col-lg-9 offset-lg-1 slider-info-container">
            <h2 className="choose-title header-2">Condividi la tua scelta</h2>
            <p className="paragraph choose-paragraph">
              Scegli il poster che vuoi condividere e diffondi il messaggio sui social.
            </p>
          </div>
          <div className="navigation-buttons col">
            <IconButton id="left-button" icon={`${ArrowLeft}#arrow-icon`} />
            <IconButton id="right-button" icon={`${ArrowRight}#arrow-right-icon`} />
          </div>
          <div className="col-lg-11 offset-lg-1 swiper-slider-wrapper">
            <div className="slider-container">
              <Swiper
                slidesPerView="auto"
                navigation={{ nextEl: '#right-button', prevEl: '#left-button' }}
              >
                {imageCards.map((imageCard, index) => (
                  <SwiperSlide key={`imageCard-${index}`} className="image-slide" onClick={() => openDialog(imageCard)}>
                    <img src={imageCard} className="image-card" />
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </div>

        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="play-again">
              <TextButton textLarge text="Gioca ancora" onClick={restartGame} />
            </div>
          </div>
        </div>
        <ShareDialog image={openShareDialog} open={startAnimationShareDialog} onClose={closeDialog}/>
      </div>
    </div>
  );
};

export default ThankYouPage;

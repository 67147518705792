import React, { useEffect, useState } from 'react';
import { CommonInnerNativagionProps } from '../../../hooks/useInnerNavigator';
import gsap from 'gsap';
import Draggable from 'gsap/Draggable';
import useAsset from './useAsset';
import { fadeIn, fadeOut } from '../../../utils/fadeElements';
import stagger from '../../../missions/MemoryMission/utils/stagger';
import useWindowDimensions from '../../../hooks/useWindowsDimension';

type ClassKeys = 'link' | 'description' | 'background' | 'card' | 'image' | 'helper' | 'noInteraction';

type UseMemoryClasses = { [x in ClassKeys]: string };
interface UsePuzzleParams extends Omit<CommonInnerNativagionProps, 'active'> {
  gridRef: React.RefObject<HTMLDivElement>;
  classes: UseMemoryClasses;
}

interface MappedCell {
  row: number;
  col: number;
  x: number;
  y: number;
}

interface Sortable {
  cell: MappedCell;
  dragger: Draggable;
  element: HTMLElement;
  index: number;
  setIndex: (index: number) => void;
}

const TOTAL_ROWS = 3;
const TOTAL_COLS = 3;

const usePuzzle = ({
  gridRef,
  classes: { link, background, description, card, image, helper, noInteraction },
  onComplete,
  goBack,
}: UsePuzzleParams): void => {
  gsap.registerPlugin(Draggable);
  const [linkElement, setLinkElement] = useState<HTMLElement | null>();
  const [buttonHelper, setButtonHelper] = useState<HTMLElement | null>();
  const { orderedAsset } = useAsset();
  const { width } = useWindowDimensions();
  const clampCol = gsap.utils.clamp(0, TOTAL_COLS - 1);
  const clampRow = gsap.utils.clamp(0, TOTAL_ROWS - 1);
  const targetsToFade = [`.${link}`, `.${description}`, `.${helper}`];
  let sortables: Sortable[] = [];
  const tl = gsap.timeline();

  useEffect(() => {
    startAnimation();
    setLinkElement(document.querySelector<HTMLElement>(`.${link}`));
    setButtonHelper(document.querySelector<HTMLElement>(`.${helper}`));

    return () => {
      tl.kill();
    };
  }, []);

  useEffect(() => {
    if (linkElement) {
      linkElement.addEventListener('click', () => animationOut().then(goBack));
    }
    return () => {
      linkElement?.removeEventListener('click', () => animationOut().then(goBack));
    };
  }, [linkElement]);

  useEffect(() => {
    if (buttonHelper) {
      buttonHelper.addEventListener('pointerdown', openSolvedPuzzleAnimation);
      buttonHelper.addEventListener('pointerup', closeSolvedPuzzleAnimation);
    }
    return () => {
      buttonHelper?.removeEventListener('pointerdown', openSolvedPuzzleAnimation);
      buttonHelper?.removeEventListener('pointerup', closeSolvedPuzzleAnimation);
    };
  }, [buttonHelper]);

  useEffect(() => {
    if (gridRef.current) {
      const rowSize = gridRef.current.offsetHeight / TOTAL_ROWS;
      const colSize = gridRef.current.offsetWidth / TOTAL_COLS;
      sortables = [...gridRef.current.querySelectorAll<HTMLElement>(`.${card}`)].map((element, index) =>
        Sortable(element, index, colSize, rowSize, getMappedCells(colSize, rowSize)),
      );
    }
  }, [width]);

  const getMappedCells = (colSize: number, rowSize: number) => {
    const cells: MappedCell[] = [];
    [...Array(TOTAL_ROWS).keys()].map((row) =>
      [...Array(TOTAL_COLS).keys()].map((col) =>
        cells.push({
          row,
          col,
          x: col * colSize,
          y: row * rowSize,
        }),
      ),
    );
    return cells;
  };

  const startAnimation = () => {
    fadeIn(targetsToFade);
    openSolvedPuzzleAnimation().then(() => {
      setTimeout(() => {
        closeSolvedPuzzleAnimation();
        stagger(`.${card}`, TOTAL_COLS);
      }, 1500);
    });
  };

  const openSolvedPuzzleAnimation = () => {
    gridRef.current?.classList.add(`${noInteraction}`);
    return tl
      .clear()
      .to(`.${card}`, { opacity: 0, duration: 0 })
      .to(`.${background}`, {
        zIndex: 1,
        duration: 0,
      })
      .to(`.${background}`, {
        duration: 0,
        opacity: 1,
      });
  };

  const closeSolvedPuzzleAnimation = () => {
    return tl
      .clear()
      .to(`.${card}`, { opacity: 1, duration: 0 })
      .to(`.${background}`, {
        opacity: 0,
        delay: 0,
      })
      .to(`.${background}`, {
        zIndex: -1,
      })
      .then(() => {
        gridRef.current?.classList.remove(`${noInteraction}`);
      });
  };

  const animationOut = () => {
    fadeOut(targetsToFade);
    return stagger(`.${card}`, TOTAL_COLS, 'end');
  };

  const handleWin = () => {
    if (!sortables.some((item, index) => item.element.dataset.puzzle !== orderedAsset[index].label)) {
      animationOut().then(onComplete);
    }
  };

  const changeIndex = (item: Sortable, to: number, sameRow: boolean, sameCol: boolean) => {
    // Check if adjacent to new position
    if ((sameRow && !sameCol) || (!sameRow && sameCol)) {
      // Swap positions in array
      const temp = sortables[to];
      sortables[to] = item;
      sortables[item.index] = temp;
    } else {
      // Change position in array
      arrayMove(sortables, item.index, to);
    }
    // Simple, but not optimized way to change element's position in DOM. Not always necessary.
    sortables.forEach((sortable: Sortable) => gridRef.current?.appendChild(sortable.element));
    // Set index for each sortable
    sortables.forEach((sortable: Sortable, index: number) => sortable.setIndex(index));
  };

  const Sortable = (element: HTMLElement, index: number, colSize: number, rowSize: number, cells: MappedCell[]) => {
    const content = element.querySelector(`.${image}`);

    const animation = gsap.to(content, {
      duration: 0.3,
      boxShadow: 'rgba(0,0,0,0.2) 0px 16px 32px 0px',
      force3D: true,
      scale: 1.1,
      paused: true,
    });

    const downAction = () => {
      animation.play();
      sortable.dragger.update();
    };

    const dragAction = () => {
      // Calculate the new index
      const col = clampCol(Math.round(sortable.dragger.x / colSize));
      const row = clampRow(Math.round(sortable.dragger.y / rowSize));
      const sameCol = col === sortable.cell.col;
      const sameRow = row === sortable.cell.row;
      // Check if position has changed
      if (!sameRow || !sameCol) {
        const index = TOTAL_COLS * row + col;
        // Update the model
        changeIndex(sortable, index, sameRow, sameCol);
      }
    };

    const upAction = () => {
      animation.reverse();
      layout();
      handleWin();
    };

    const dragger = new Draggable(element, {
      zIndexBoost : false,
      onDragStart: downAction,
      onRelease: upAction,
      onDrag: dragAction,
    });

    // let position = element._gsTransform;
    const getProp = gsap.getProperty(element);

    const setIndex = (index: number) => {
      const cell = cells[index];
      const dirty = getProp('x') !== cell.x || getProp('y') !== cell.y;
      sortable.cell = cell;
      sortable.index = index;
      // Don't layout if you're dragging
      if (!dragger.isDragging && dirty) layout();
    };

    // Public properties and methods
    const sortable = {
      cell: cells[index],
      dragger,
      element,
      index,
      setIndex,
    };

    gsap.set(element, {
      x: sortable.cell.x,
      y: sortable.cell.y,
    });

    const layout = () => {
      gsap.to(element, {
        duration: 0.3,
        x: sortable.cell.x,
        y: sortable.cell.y,
      });
    };
    return sortable;
  };

  // Changes an elements's position in array
  const arrayMove = (array: Sortable[], from: number, to: number) => {
    array.splice(to, 0, array.splice(from, 1)[0]);
  };
};

export default usePuzzle;

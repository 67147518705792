import { useState } from 'react';

type InnerNavigator = 'start' | 'in-progress' | 'completed';

type UseInnerNavigatorReturnType = [
  innerNavigator: InnerNavigator,
  setInnerNavigator: React.Dispatch<React.SetStateAction<InnerNavigator>>,
];

export interface CommonInnerNativagionProps {
  onComplete: () => void;
  goBack: () => void;
  active: boolean;
}

const useInnerNavigator = (): UseInnerNavigatorReturnType => {
  const [innerNavigator, setInnerNavigator] = useState<InnerNavigator>('start');

  return [innerNavigator, setInnerNavigator];
};

export default useInnerNavigator;

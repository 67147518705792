import React, { useRef } from 'react';
import './style.scss';
import useFocus from '../../hooks/useFocus';
import IconLinkButtonProps from './IconLinkButton.models';

const IconButton = ({ icon, ...props }: IconLinkButtonProps): JSX.Element => {
  const ref = useRef<HTMLAnchorElement>(null);
  useFocus(ref, 'with-keyboard');

  return (
    <a className="link-rounded-container btn" ref={ref} {...props}>
      {icon && (
        <svg viewBox="0 0 24 24" className="svg-icon">
          <use xlinkHref={icon} href={icon}></use>
        </svg>
      )}
    </a>
  );
};

export default IconButton;

import React from 'react';
import './IntroStepOne.scss';
import Background from '../../../../assets/lotties/universo_bg_stelle.json';
import Planets from '../Planets/Planets';
import Lottie from 'react-lottie';
import EllipsedText from '../../../../components/EllipsedText/EllipsedText';
import ScrollIndicator from '../ScrollIndicator/ScrollIndicator';


const IntroStepOne: React.FC = () => {
  return (
    <section id="home" className="section">
      <div className="home-wrapper">
        <div className="homesections">
          <div className="content">
            <svg
              data-text="Pianeta Terra, 3002"
              viewBox="0 0 265 441"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="door-svg">
              <defs>
                <path id="doorPath" d="M0.1,441V132.4C0.1,132.4,1,0,132.5,0s132.4,132.4,132.4,132.4V441H0.1z" />
              </defs>
              <text id="text" fill="currentColor" dy="-5%" className="header-1 header-1-size-text">
                <textPath className="text-path" xlinkHref="#path" href="#doorPath" startOffset="0%" textLength={500}>
                  Un futuro da salvare
                </textPath>
              </text>
            </svg>
            <div className="door-mask">
              <Lottie
                options={{
                  animationData: Background,
                  loop: true,
                  autoplay: true,
                  rendererSettings: { preserveAspectRatio: 'xMidYMid slice' },
                }}
              />
              <div className="planets-container">
                <Planets />
              </div>
            </div>
            <p className="paragraph content-label">Pianeta Terra, 3002.</p>
          </div>
        </div>
        <div className="description">
          <ScrollIndicator />
          <EllipsedText type="intro-step-one">
            <h1 className="header-2 label">Il futuro</h1>
          </EllipsedText>
          <p className="paragraph description-paragraph">
            Lande desolate e aride, acque inquinate e aria tossica hanno spazzato via ogni colore e ogni profumo che ricordava la vita.
          </p>
        </div>
      </div>
    </section>
  );
};

export default IntroStepOne;

import React, { useEffect, useState } from 'react';
import { CommonInnerNativagionProps } from '../../../hooks/useInnerNavigator';
import { fadeIn, fadeOut } from '../../../utils/fadeElements';
import stagger from '../utils/stagger';

type ClassKeys = 'card' | 'link' | 'description' | 'flipped' | 'hasMatch' | 'noEvent';
type UseMemoryClasses = { [x in ClassKeys]: string };
interface UseMemoryParams extends Omit<CommonInnerNativagionProps, 'active'> {
  ref: React.RefObject<HTMLDivElement>;
  classes: UseMemoryClasses;
}

const ANIMATION_DELAY = 1500;
const COLUMS = 3;

const useMemory = ({
  ref,
  classes: { card, description, link, flipped, hasMatch, noEvent },
  onComplete,
  goBack,
}: UseMemoryParams): void => {
  const [cards, setCards] = useState<HTMLElement[]>();
  const [linkElement, setLinkElement] = useState<HTMLElement | null>();
  const targetsToFade = [`.${link}`, `.${description}`];

  const animationOut = () => {
    fadeOut(targetsToFade);
    return stagger(`.${card}`, COLUMS, 'end');
  };

  const checkAllCards = () => {
    if (cards?.every((card) => card.classList.contains(hasMatch))) {
      animationOut().then(onComplete);
    }
  };

  const stopEvent = () => {
    ref.current?.classList.add(noEvent);

    setTimeout(() => {
      ref.current?.classList.remove(noEvent);
    }, ANIMATION_DELAY);
  };

  const checkIfMatch = ([firstCard, secondCard]: HTMLElement[]) => {
    const removeFlippedClasses = () => [firstCard, secondCard].forEach(({ classList }) => classList.remove(flipped));
    if (firstCard.dataset.memory === secondCard.dataset.memory) {
      removeFlippedClasses();
      [firstCard, secondCard].forEach(({ classList }) => classList.add(hasMatch));
      checkAllCards();
    } else {
      stopEvent();
      setTimeout(removeFlippedClasses, ANIMATION_DELAY);
    }
  };

  const onFlip = (card: HTMLElement) => {
    card.classList.add(flipped);
    const flippedCards = cards?.filter((card) => card.classList.contains(flipped));

    if (flippedCards?.length === 2) {
      checkIfMatch(flippedCards);
    }
  };

  const startAnimation = () => {
    fadeIn(targetsToFade);
    stagger(`.${card}`, COLUMS).then(() => {
      setTimeout(() => {
        ref.current?.classList.remove(noEvent);
        cards?.forEach((card) => card.classList.remove(flipped));
      }, ANIMATION_DELAY);
    });
  };

  useEffect(() => {
    setLinkElement(document.querySelector<HTMLElement>(`.${link}`));
  }, []);

  useEffect(() => {
    if (ref.current) {
      setCards([...ref.current.querySelectorAll<HTMLElement>(`.${card}`)]);
    }
  }, [ref]);

  useEffect(() => {
    if (cards) {
      startAnimation();
      cards.forEach((card) => {
        card.addEventListener('click', () => onFlip(card));
      });
    }

    return () => {
      cards?.forEach((card) => {
        card.removeEventListener('click', () => onFlip(card));
      });
    };
  }, [cards]);

  useEffect(() => {
    if (linkElement) {
      linkElement.addEventListener('click', () => animationOut().then(goBack));
    }

    return () => {
      linkElement?.removeEventListener('click', () => animationOut().then(goBack));
    };
  }, [linkElement]);
};

export default useMemory;

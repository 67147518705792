import './IntroController.scss';
import React from 'react';
import Scroller from '../../../../components/Scroller/Scroller';
import IntroControllerProps from './IntroController.models';
import IconLink from '../../../../components/IconLink/IconLink';
import { useHistory } from 'react-router';
import { Routes } from '../../../../App';

const IntroController: React.FC<IntroControllerProps> = ({ disableSkip, disableScroller, onClick }) => {
  const history = useHistory();

  const skip = () => {
    history.push(Routes.Missions);
  };
  return (
    <div className="intro-controller">
      {!disableScroller && (
        <div className="scroller-container">
          <Scroller onClick={onClick} />
        </div>
      )}
      {!disableSkip && (
        <div className="skip-container">
          <IconLink text="SALTA INTRO" onClick={skip} />
        </div>
      )}
    </div>
  );
};

export default IntroController;

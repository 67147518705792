import './IconButton.scss';
import React from 'react';
import { useRef } from 'react';
import useFocus from '../../hooks/useFocus';
import IconButtonProps from './IconButton.models';
import getClasses from '../../utils/getClasses';

const IconButton: React.FC<IconButtonProps> = ({ className, id, icon, onClick }) => {
  const ref = useRef<HTMLButtonElement>(null);
  useFocus(ref, 'with-keyboard');

  return (
    <button
      ref={ref}
      id={id}
      className={getClasses({
        btn: true,
        'icon-btn': true,
        [`${className}`]: Boolean(className),
      })}
      onClick={onClick}>
      <svg viewBox="0 0 24 24" className="svg-icon">
        <use xlinkHref={icon} href={icon}></use>
      </svg>
    </button>
  );
};

export default IconButton;

import { gsap, Linear } from 'gsap';
import React, { useRef, useLayoutEffect } from 'react';
import ScrollerProps from './Scroller.models';
import './style.scss';
import useFocus from '../../hooks/useFocus';

const Scroller = ({ color, onClick }: ScrollerProps): JSX.Element => {
  const tl = useRef<gsap.core.Timeline | null>(null);
  const btnRef = useRef<HTMLButtonElement | null>(null);
  const q = gsap.utils.selector(btnRef);
  useFocus(btnRef, 'with-keyboard');

  useLayoutEffect(() => {
    tl.current = gsap.timeline();
    const rotate = gsap.to(q('.text-path'), {
      duration: 6,
      repeat: -1,
      ease: Linear.easeInOut,
      rotation: 360,
      transformOrigin: 'center center',
    });

    return () => {
      rotate.kill();
      tl.current?.kill();
    };
  }, []);

  const mouseEnterHandler = () => {
    tl.current
      ?.clear()
      .to(q('#circle'), {
        r: 50,
        duration: 0.6,
        ease: Linear.easeNone,
      })
      .to(q('#circle'), {
        r: 40,
        duration: 0.6,
        ease: Linear.easeNone,
        yoyo: true,
        repeat: -1,
      });
  };

  const mouseLeaveHandler = () => {
    tl.current?.clear().to(q('#circle'), {
      r: 0,
      duration: 0.3,
      ease: Linear.easeNone,
    });
  };

  return (
    <button
      onMouseEnter={mouseEnterHandler}
      onMouseLeave={mouseLeaveHandler}
      onClick={onClick}
      className="header-1 scroller-button btn"
      style={{ color: color }}
      ref={btnRef}>
      <svg
        className="scroller-svg"
        width="72"
        height="72"
        viewBox="0 0 160 160"
        fill="none"
        stroke="currentColor"
        xmlns="http://www.w3.org/2000/svg">
        <defs>
          <path
            id="svg-path"
            d="M160 80C160 124.183 124.183 160 80 160C35.8172 160 0 124.183 0 80C0 35.8172 35.8172 0 80 0C124.183 0 160 35.8172 160 80Z"
            fill="#C4C4C4"
          />
        </defs>
        <text className="text-path" fill="currentColor">
          <textPath xlinkHref="#svg-path" href="#svg-path" textLength="500">
            Scorri in basso — Scorri in basso —
          </textPath>
        </text>
        <g stroke="none">
          <circle cx="80" cy="80" r="0" fill="currentColor" id="circle" />
        </g>
      </svg>
    </button>
  );
};

export default Scroller;

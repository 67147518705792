import './ConnectDots.scss';
import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import gsap from 'gsap';
import MotionPathPlugin from 'gsap/MotionPathPlugin';
import DrawSVGPlugin from 'gsap/DrawSVGPlugin';
import CSSPlugin from 'gsap/CSSPlugin';
import { TransitionProps } from '@material-ui/core/transitions/transition';
import Slide from '@material-ui/core/Slide';
import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import Avatar1 from '../../assets/images/avatar-1.svg';
import Avatar2 from '../../assets/images/avatar-2.svg';
import Avatar3 from '../../assets/images/avatar-3.svg';
import Avatar4 from '../../assets/images/avatar-4.svg';
import Avatar5 from '../../assets/images/avatar-5.svg';
import Avatar6 from '../../assets/images/avatar-6.svg';
import Avatar7 from '../../assets/images/avatar-7.svg';
import Avatar8 from '../../assets/images/avatar-8.svg';
import Avatar9 from '../../assets/images/avatar-9.svg';
import IconLink from '../../components/IconLink/IconLink';
import colors from '../../assets/scss/colors.module.scss';
import ArrowBackIcon from '../../assets/icons/arrow.svg';
import CloseIcon from '../../assets/icons/close.svg';
import { CommonInnerNativagionProps } from 'src/hooks/useInnerNavigator';
import { fadeIn, fadeOut } from 'src/utils/fadeElements';

gsap.registerPlugin(MotionPathPlugin, DrawSVGPlugin, CSSPlugin);
interface ConnectDotsGameData {
  points: Element[];
  targets: SVGPathElement[];
  paths: SVGPathElement[];
  drawablePaths: SVGPathElement[];
}

interface Message {
  avatar: string;
  author: string;
  message: string;
}

const DIALOG_AUTO_CLOSING_TIME = 8000;

const DialogTransition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children?: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const MESSAGES = [
  {
    avatar: Avatar1,
    author: 'Fiamma Palermo',
    message:
      'Compra meno cose e indossale più a lungo. Puoi farlo se acquisti capi di qualità e fatti per durare nel tempo.',
  },
  {
    avatar: Avatar2,
    author: 'Marta Cocci',
    message:
      'Il tuo stile personale è come te: unico! Non devi per forza seguire le mode stagionali per esprimere la tua identità.',
  },
  {
    avatar: Avatar3,
    author: 'Franco Marchesi',
    message: 'Evita l’acquisto d’impulso: nella maggioranza dei casi è destinato al fondo dell’armadio!',
  },
  {
    avatar: Avatar4,
    author: 'Marilena Boni',
    message:
      'Impara a leggere le etichette e le certificazioni di qualità ambientale e sociale, sapendo che non potranno mai dire tutto del capo che stai per acquistare.',
  },
  {
    avatar: Avatar5,
    author: 'Roberto Volpe',
    message:
      'Informati su i tuoi brand di moda preferiti e fai sapere loro che per te sostenibilità e trasparenza sono importanti.',
  },
  {
    avatar: Avatar6,
    author: 'Ninfa Romano',
    message:
      'Indossa tessuti in fibre naturali e, se proprio devi comprare tessuti sintetici, scegli quelli ottenuti dal riciclo di materiali plastici.',
  },
  {
    avatar: Avatar7,
    author: 'Aurora Dellucci',
    message:
      'Evita lunghi spostamenti in auto da un negozio all’altro: muoviti a piedi o in bici per fare shopping oppure acquista on line in modo consapevole.',
  },
  {
    avatar: Avatar8,
    author: 'Mario Rossi',
    message: 'Scopri il fascino dei negozi vintage, dell’usato e delle nuove proposte del commercio equo e solidale.',
  },
  {
    avatar: Avatar9,
    author: 'Luisa Calabrese',
    message:
      'Indossa capi re-fashion, trasformando i tuoi vecchi vestiti e ricorda che non c’è un solo modo per rendere unico il tuo guardaroba, come non c’è un solo modo per essere consumatori consapevoli.',
  },
];

const ConnectDots: React.FC<Omit<CommonInnerNativagionProps, 'active'>> = ({ goBack, onComplete }) => {
  const ref = useRef(null);
  const targetsToFade = ['.back-btn-container', '.description', '.point', '.point-number', '.drawable-line-path'];
  const [dialogData, setDialogData] = useState<Message>();
  const [openDialog, setOpenDialog] = useState(false);
  const [currentPointIndex, setCurrentPointIndex] = useState(0);
  const [data, setData] = useState<ConnectDotsGameData>();
  const q = gsap.utils.selector(ref);
  const onCloseDialog = () => {
    setOpenDialog(false);
    setTimeout(() => setDialogData(undefined), 500);
  };
  const customStagger = (index: number) => (index + 1) * 0.05;

  const fadeOutAnimation = useCallback((cb: () => void) => {
    const animation = fadeOut(targetsToFade, customStagger);
    let backgroundAnimation: gsap.core.Tween;
    animation.then(() => {
      backgroundAnimation = gsap.to('.connect-dots-container', { opacity: 0 });
      backgroundAnimation.then(cb);
    });

    return () => {
      animation.kill();
      backgroundAnimation?.kill();
    };
  }, []);

  useLayoutEffect(() => {
    const targets = ['html', 'body', '#root'];
    const fadeInAnimation = fadeIn(targetsToFade, customStagger);
    gsap.set(targets, { height: '100vh', overflow: 'hidden' });
    const backgroundAnimation = gsap.fromTo('.connect-dots-container', { opacity: 0 }, { opacity: 1 });
    if (document) {
      const points = q('.point');
      const targets = q<SVGPathElement>('.target-area');
      const paths = q<SVGPathElement>('.line-path');
      const drawablePaths = q<SVGPathElement>('.drawable-line-path');

      gsap.set('.drawable-line-path', { drawSVG: '0%', ease: 'none' });

      setData({
        points,
        targets,
        paths,
        drawablePaths,
      });
    }

    return () => {
      fadeInAnimation?.kill();
      backgroundAnimation?.kill();
      gsap.set(targets, { clearProps: 'height,overflow' });
    };
  }, [document]);

  useLayoutEffect(() => {
    let pathAnimation: gsap.core.Tween;
    let pointTl: gsap.core.Timeline;
    let unsubscribe: () => void;

    if (data) {
      const { points, targets, drawablePaths } = data;
      const currentPointTarget = points[currentPointIndex];
      const nextPointTarget = points[currentPointIndex + 1];
      const currentDragablePath = drawablePaths[currentPointIndex];
      const isLastPoint = currentPointIndex === points.length - 1;
      const fillCompletedPoint = (target: Element) => {
        gsap.to(target, { strokeWidth: 0, fill: colors.flatLightPurple });
      };
      fillCompletedPoint(currentPointTarget);
      if (nextPointTarget) {
        targets[currentPointIndex + 1].classList.add('cursor-pointer');
        pointTl = gsap.timeline({ repeat: -1, yoyo: true }).fromTo(
          points[currentPointIndex + 1],
          {
            stroke: colors.flatDarkBlue,
            strokeOpacity: 0.5,
            strokeWidth: 1,
          },
          {
            strokeWidth: 20,
          },
        );
      }
      
      const handler = () => {
        pathAnimation = gsap.to(
          currentDragablePath,
          {
            drawSVG: '100%',
            ease: 'none',
            duration: 0.3,
          },
        );
        pathAnimation.then(() => {
          targets[currentPointIndex + 1].classList.add('disable-pointer');
          setCurrentPointIndex((oldIndex) => oldIndex + 1);
        });
      };

      if (currentPointIndex >= 0 && !isLastPoint) {
        targets[currentPointIndex + 1].addEventListener('click', handler);
        unsubscribe = handler;
      }
    }

    return () => {
      const nextTarget = data?.targets[currentPointIndex + 1];
      pointTl?.kill();
      pathAnimation?.kill();
      if (unsubscribe && nextTarget) {
        nextTarget.removeEventListener('click', unsubscribe);
      }
    };
  }, [currentPointIndex, data]);

  useEffect(() => {
    const timers: NodeJS.Timer[] = [];

    if (currentPointIndex > 0) {
      if (openDialog) {
        setOpenDialog(false);
      }
      timers.push(setTimeout(() => setDialogData(MESSAGES[currentPointIndex - 1]), openDialog ? 500 : 0));
      timers.push(setTimeout(() => setOpenDialog(true), openDialog ? 600 : 100));
      timers.push(setTimeout(onCloseDialog, DIALOG_AUTO_CLOSING_TIME));
    }

    return () => {
      timers.forEach(clearTimeout);
    };
  }, [currentPointIndex]);

  useEffect(() => {
    if (typeof dialogData === 'undefined' && data && currentPointIndex === data?.points.length - 1) {
      fadeOutAnimation(onComplete);
    }
  }, [dialogData]);

  return (
    <div ref={ref} className="container-fluid connect-dots-container">
      <div className="row">
        <div className="col-lg-10 offset-lg-1">
            <div className="back-btn-container">
              <IconLink
                text="Indietro"
                upperCase
                color={colors.flatDarkBlue}
                icon={`${ArrowBackIcon}#arrow-icon`}
                onClick={() => fadeOutAnimation(goBack)}
              />
            </div>
            <p className="paragraph-bold description">Clicca su ogni numero, scopri come vivere in modo sostenibile e completa il gioco.</p>
            <div className="dots-grid">
              <svg viewBox="0 0 427 458" fill="none" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMinYMin meet">
                <path
                  d="M119.5 108L322.5 75.5"
                  stroke="#442ACA"
                  strokeWidth="8"
                  strokeDasharray=""
                  fill="none"
                  className="drawable-line-path"
                />
                <path d="M119.5 108L322.5 75.5" stroke="transparent" strokeWidth="8" fill="none" className="line-path" />

                <path
                  d="M322.5 75.5L239 144"
                  stroke="#442ACA"
                  strokeWidth="8"
                  strokeDasharray=""
                  fill="none"
                  className="drawable-line-path"
                />
                <path d="M322.5 75.5L239 144" stroke="trasparent" strokeWidth="8" fill="none" className="line-path" />

                <path
                  d="M239 144L95 177.5"
                  stroke="#442ACA"
                  strokeWidth="8"
                  strokeDasharray=""
                  fill="none"
                  className="drawable-line-path"
                />
                <path d="M239 144L95 177.5" stroke="trasparent" strokeWidth="8" fill="none" className="line-path" />

                <path
                  d="M95 177.5L324 202.5"
                  stroke="#442ACA"
                  strokeWidth="8"
                  strokeDasharray=""
                  fill="none"
                  className="drawable-line-path"
                />
                <path d="M95 177.5L324 202.5" stroke="trasparent" strokeWidth="8" fill="none" className="line-path" />

                <path
                  d="M323 202.5L152.5 270"
                  stroke="#442ACA"
                  strokeWidth="8"
                  strokeDasharray=""
                  fill="none"
                  className="drawable-line-path"
                />
                <path d="M323 202.5L152.5 270" stroke="trasparent" strokeWidth="8" fill="none" className="line-path" />

                <path
                  d="M151.5 270L321.5 296.5"
                  stroke="#442ACA"
                  strokeWidth="8"
                  strokeDasharray=""
                  fill="none"
                  className="drawable-line-path"
                />
                <path d="M151.5 270L321.5 296.5" stroke="trasparent" strokeWidth="8" fill="none" className="line-path" />

                <path
                  d="M321.5 297L70.5 359"
                  stroke="#442ACA"
                  strokeWidth="8"
                  strokeDasharray=""
                  fill="none"
                  className="drawable-line-path"
                />
                <path d="M321.5 297L70.5 359" stroke="trasparent" strokeWidth="8" fill="none" className="line-path" />

                <path
                  d="M72 359L229.5 383"
                  stroke="#442ACA"
                  strokeWidth="8"
                  strokeDasharray=""
                  fill="none"
                  className="drawable-line-path"
                />
                <path d="M72 359L229.5 383" stroke="trasparent" strokeWidth="8" fill="none" className="line-path" />

                <path
                  d="M230 383H355"
                  stroke="#442ACA"
                  strokeWidth="8"
                  strokeDasharray=""
                  fill="none"
                  className="drawable-line-path"
                />
                <path d="M230 383H355" stroke="trasparent" strokeWidth="8" fill="none" className="line-path" />

                <path
                  d="M98 108.5C98 97.7304 106.73 89 117.5 89V89C128.27 89 137 97.7304 137 108.5V108.5C137 119.27 128.27 128 117.5 128V128C106.73 128 98 119.27 98 108.5V108.5Z"
                  fill="#192942"
                  className="point"
                />
                <path
                  d="M117.195 113H119.148V101.961H117.273V102.266C117.273 103.031 116.844 103.445 116.094 103.445H114.281V104.953H117.195V113Z"
                  fill="white"
                  className="point-number"
                />
                <path
                  d="M307 75.5C307 64.7304 315.73 56 326.5 56V56C337.27 56 346 64.7304 346 75.5V75.5C346 86.2696 337.27 95 326.5 95V95C315.73 95 307 86.2696 307 75.5V75.5Z"
                  fill="#192942"
                  className="point"
                />
                <path
                  d="M322.227 80H330.594V78.2109H325.492C326.016 77.8906 326.773 77.3281 327.523 76.6094C328.789 75.3906 330.055 73.7891 330.055 72.0781C330.055 70.1953 328.531 68.8125 326.5 68.8125C324.07 68.8125 322.656 70.7969 322.656 72.8516L324.594 72.9766C324.641 71.8438 325.078 70.5938 326.516 70.5938C327.602 70.5938 328.07 71.3125 328.07 72.1953C328.07 73.3281 327.297 74.3672 326.305 75.3438C324.594 77.0234 322.289 78.3828 322.227 78.4297V80Z"
                  fill="white"
                  className="point-number"
                />
                <path
                  d="M220 144.5C220 133.73 228.73 125 239.5 125V125C250.27 125 259 133.73 259 144.5V144.5C259 155.27 250.27 164 239.5 164V164C228.73 164 220 155.27 220 144.5V144.5Z"
                  fill="#192942"
                  className="point"
                />
                <path
                  d="M239.375 149.156C241.516 149.156 243.453 148.023 243.453 145.703C243.422 144.602 242.969 143.594 241.898 143C242.492 142.469 242.859 141.688 242.859 140.805C242.859 138.922 241.164 137.797 239.32 137.797C237.422 137.797 235.789 139 235.789 141.078L237.656 141.211C237.672 140.234 238.344 139.641 239.352 139.641C240.25 139.641 240.953 140.102 240.953 141.047C240.953 141.75 240.57 142.312 239.656 142.312H238.383V143.977H239.656C240.844 143.977 241.562 144.602 241.562 145.594C241.562 146.773 240.523 147.336 239.391 147.336C238.109 147.336 237.148 146.633 237.148 145.398L235.258 145.516C235.258 147.961 237.164 149.156 239.375 149.156Z"
                  fill="white"
                  className="point-number"
                />
                <path
                  d="M76 177.5C76 166.73 84.7304 158 95.5 158V158C106.27 158 115 166.73 115 177.5V177.5C115 188.27 106.27 197 95.5 197V197C84.7304 197 76 188.27 76 177.5V177.5Z"
                  fill="#192942"
                  className="point"
                />
                <path
                  d="M96.0312 182H97.9766V179.406H99.7422V177.602H97.9766V170.961H96.3125L90.9219 178.258V179.406H96.0312V182ZM93.4688 177.602L96.0312 174V177.602H93.4688Z"
                  fill="white"
                  className="point-number"
                />
                <path
                  d="M305 202.5C305 191.73 313.73 183 324.5 183V183C335.27 183 344 191.73 344 202.5V202.5C344 213.27 335.27 222 324.5 222V222C313.73 222 305 213.27 305 202.5V202.5Z"
                  fill="#192942"
                  className="point"
                />
                <path
                  d="M324.438 207.156C326.641 207.156 328.484 205.336 328.484 203.242C328.484 201.141 326.789 199.414 324.727 199.414C324.164 199.414 323.617 199.531 323.008 199.852L323.414 197.781H327.984V195.961H321.844L320.773 201.766L322.562 202.219C322.961 201.578 323.719 201.195 324.438 201.195C325.734 201.195 326.469 202.133 326.469 203.227C326.469 204.297 325.633 205.367 324.438 205.367C323.094 205.367 322.5 204.297 322.289 203.289L320.453 203.797C320.852 205.656 322.453 207.156 324.438 207.156Z"
                  fill="white"
                  className="point-number"
                />
                <path
                  d="M132 269.5C132 258.73 140.73 250 151.5 250V250C162.27 250 171 258.73 171 269.5V269.5C171 280.27 162.27 289 151.5 289V289C140.73 289 132 280.27 132 269.5V269.5Z"
                  fill="#192942"
                  className="point"
                />
                <path
                  d="M151.516 274.156C153.852 274.156 155.547 272.336 155.547 270.289C155.547 268.141 153.727 266.594 151.531 266.594H151.289L153.672 262.961H151.406L148.094 268.398C147.711 268.961 147.5 269.617 147.5 270.352C147.5 272.461 149.289 274.156 151.516 274.156ZM151.531 272.305C150.383 272.305 149.438 271.406 149.438 270.305C149.438 269.203 150.383 268.305 151.531 268.305C152.664 268.305 153.609 269.203 153.609 270.305C153.609 271.406 152.664 272.305 151.531 272.305Z"
                  fill="white"
                  className="point-number"
                />
                <path
                  d="M303 296.5C303 285.73 311.73 277 322.5 277V277C333.27 277 342 285.73 342 296.5V296.5C342 307.27 333.27 316 322.5 316V316C311.73 316 303 307.27 303 296.5V296.5Z"
                  fill="#192942"
                  className="point"
                />
                <path
                  d="M319.461 301H321.648L326.547 290.969V289.961H318.578V291.766H324.148L319.461 301Z"
                  fill="white"
                  className="point-number"
                />
                <path
                  d="M52 359.5C52 348.73 60.7304 340 71.5 340V340C82.2696 340 91 348.73 91 359.5V359.5C91 370.27 82.2696 379 71.5 379V379C60.7304 379 52 370.27 52 359.5V359.5Z"
                  fill="#192942"
                  className="point"
                />
                <path
                  d="M71.5 364.156C73.5938 364.156 75.5781 363.07 75.5781 360.812C75.5781 359.617 75.0156 358.641 73.9766 358.031C74.6172 357.484 75.0156 356.703 75.0156 355.789C75.0156 353.906 73.3203 352.797 71.5 352.797C69.6719 352.797 67.9766 353.891 67.9766 355.805C67.9766 356.672 68.3281 357.453 69.0156 358.031C68.0469 358.609 67.4219 359.586 67.4219 360.828C67.4219 363.055 69.3906 364.156 71.5 364.156ZM71.5312 357.297C70.5547 357.297 69.9297 356.719 69.9297 355.844C69.9297 354.898 70.6641 354.445 71.5312 354.445C72.4453 354.445 73.1328 354.961 73.1328 355.875C73.1328 356.781 72.4609 357.297 71.5312 357.297ZM71.4844 362.461C70.3281 362.461 69.3516 361.906 69.3516 360.719C69.3516 359.602 70.25 358.977 71.4844 358.977C72.7031 358.977 73.6094 359.602 73.6094 360.734C73.6094 361.906 72.6484 362.461 71.4844 362.461Z"
                  fill="white"
                  className="point-number"
                />
                <path
                  d="M210 382.5C210 371.73 218.73 363 229.5 363V363C240.27 363 249 371.73 249 382.5V382.5C249 393.27 240.27 402 229.5 402V402C218.73 402 210 393.27 210 382.5V382.5Z"
                  fill="#192942"
                  className="point"
                />
                <path
                  d="M227.32 387H229.594L232.906 381.562C233.289 381 233.5 380.344 233.5 379.609C233.5 377.492 231.711 375.797 229.484 375.797C227.148 375.797 225.453 377.625 225.453 379.672C225.453 381.812 227.273 383.367 229.469 383.367H229.711L227.32 387ZM229.469 381.656C228.328 381.656 227.391 380.758 227.391 379.656C227.391 378.555 228.328 377.656 229.469 377.656C230.617 377.656 231.562 378.555 231.562 379.656C231.562 380.758 230.617 381.656 229.469 381.656Z"
                  fill="white"
                  className="point-number"
                />
                <path
                  d="M336 382.5C336 371.73 344.73 363 355.5 363V363C366.27 363 375 371.73 375 382.5V382.5C375 393.27 366.27 402 355.5 402V402C344.73 402 336 393.27 336 382.5V382.5Z"
                  fill="#192942"
                  className="point"
                />
                <path
                  d="M350.555 387H352.508V375.961H350.633V376.266C350.633 377.031 350.203 377.445 349.453 377.445H347.641V378.953H350.555V387ZM360.219 387.156C363.18 387.156 364.57 384.234 364.57 381.492C364.57 378.742 363.148 375.781 360.109 375.781C356.766 375.781 355.742 379.125 355.742 381.477C355.742 383.812 356.797 387.156 360.219 387.156ZM360.172 385.289C358.383 385.289 357.695 383.242 357.695 381.477C357.695 379.703 358.383 377.688 360.109 377.688C361.836 377.688 362.523 379.688 362.523 381.461C362.523 383.195 361.852 385.289 360.172 385.289Z"
                  fill="white"
                  className="point-number"
                />
                <rect className="target-area" x="83" y="73" width="69" height="69" fill="transparent" />
                <rect className="target-area" x="292" y="41" width="69" height="69" fill="transparent" />
                <rect className="target-area" x="205" y="108" width="69" height="69" fill="transparent" />
                <rect className="target-area" x="61" y="143" width="69" height="69" fill="transparent" />
                <rect className="target-area" x="289" y="167" width="69" height="69" fill="transparent" />
                <rect className="target-area" x="117" y="235" width="69" height="69" fill="transparent" />
                <rect className="target-area" x="288" y="262" width="69" height="69" fill="transparent" />
                <rect className="target-area" x="37" y="328" width="69" height="69" fill="transparent" />
                <rect className="target-area" x="195" y="348" width="69" height="69" fill="transparent" />
                <rect className="target-area" x="323" y="348" width="69" height="69" fill="transparent" />
              </svg>
            </div>
          </div>
      </div>
      <Dialog
        className="dialog-custom"
        open={openDialog}
        TransitionComponent={DialogTransition}
        fullWidth
        maxWidth="xs"
        hideBackdrop
        onClose={onCloseDialog}
        transitionDuration={{
          exit: 500,
          enter: 500,
        }}>
        <DialogTitle disableTypography>
          <img src={dialogData?.avatar} className="social-card-cover" />
          <p className="social-card-author-name paragraph-extrasmall-bold">{dialogData?.author}</p>

          <div className="close-btn-container">
            <button className="btn close-btn" onClick={onCloseDialog}>
              <svg viewBox="0 0 32 32" className="close-icon" color={colors.flatDarkBlue}>
                <use xlinkHref={`${CloseIcon}#close`} href={`${CloseIcon}#close`}></use>
              </svg>
            </button>
          </div>
        </DialogTitle>
        <DialogContent>
          <p className="paragraph">{dialogData?.message}</p>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default ConnectDots;

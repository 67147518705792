import React from 'react';
import { useHistory } from 'react-router';
import useMissionsHandler from '../../hooks/useMissionsHandler';
import ConnectDots from '../../missions/ConnectDots/ConnectDots';
import useIllustrationsAsset from '../../hooks/useIllustrationsAsset';
import useInnerNavigator from '../../hooks/useInnerNavigator';
import MissionStart from '../../missions/components/MissionStart/MissionStart';
import ThirdMissionEnd from './components/ThirdMissionEnd/ThirdMissionEnd';
import { Routes } from '../../App';

const ThirdMissionPage: React.FC = () => {
  const history = useHistory();
  const { onComplete } = useMissionsHandler();
  const [innerNavigator, setInnerNavigator] = useInnerNavigator();

  const title = 'L\'unione fa la forza';
  const description =
    'Da soli non possiamo raggiungere il nostro scopo: è giunto il momento di coinvolgere e informare. Il mondo può essere salvato solo se ognuno fa la propria parte.';
  const endDescription = 'Clicca su ogni numero, scopri come vivere in modo sostenibile e completa il gioco.';
  const illustrations = useIllustrationsAsset('messages');

  return (
    <>
      {innerNavigator === 'start' && (
        <MissionStart
          title={title}
          description={description}
          endDescription={endDescription}
          illustrations={illustrations}
          onComplete={() => setInnerNavigator('in-progress')}
        />
      )}
      {innerNavigator === 'in-progress' && (
        <ConnectDots
          goBack={() => setInnerNavigator('start')}
          onComplete={() => {
            onComplete(history.location.pathname.split('/')[2]);
            setInnerNavigator('completed');
          }}
        />
      )}
      {innerNavigator === 'completed' && <ThirdMissionEnd onComplete={() => {
        history.push(Routes['Subscription']);
      }} />}
    </>
  );
};

export default ThirdMissionPage;

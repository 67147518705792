import { useState } from 'react';
import { useCookies } from 'react-cookie';
import { CookieSetOptions } from 'universal-cookie';

interface MissionsCookie {
  counter: string;
  completed: string[];
}

interface UseMissionsHandlerReturnType {
  onComplete: (id: string) => void;
  isCompletedMissionByID: (id: string) => boolean;
  options: CookieSetOptions;
  isCompleted: boolean;
  result: Partial<MissionsCookie>;
}

const tomorrow = (): Date => {
  const today = new Date();
  const tomorrow = new Date(today);
  tomorrow.setDate(tomorrow.getDate() + 1);
  return tomorrow;
};

const useMissionsHandler = (): UseMissionsHandlerReturnType => {
  const [cookies, setCookies] = useCookies(['completed']);
  const numOfMissions = 3;
  const completed: string[] = (cookies.completed as string)?.split('-') || [];
  const isCompleted = Boolean(completed.length) && numOfMissions === completed?.length;
  const options: CookieSetOptions = {
    expires: tomorrow(),
    secure: true,
  };

  const isCompletedMissionByID = (id: string) => Boolean(completed.find((currentId) => currentId === id));

  const onComplete = (id: string): void => {
    if (!isCompletedMissionByID(id)) {
      setCookies('completed', `${[...completed.filter((currentId) => currentId !== id), id].join('-')}`, options);
    }
  };

  return {
    onComplete,
    isCompletedMissionByID,
    options,
    isCompleted,
    result: {
      counter: `${numOfMissions}`,
      completed,
    },
  };
};

export default useMissionsHandler;

import './InputField.scss';
import React from 'react';
import { TextField, makeStyles, TextFieldProps } from '@material-ui/core';
import colors from '../../assets/scss/colors.module.scss';
import { convertToRem } from '../../utils/styles.utils';

const getStyles = makeStyles({
  root: {
    '& .Mui-focused': {
      color: 'unset',
    },
    '& .Mui-error': {
      color: colors.flatError,
    },
    marginBottom: convertToRem(24),
  },
  input: {
    '&::placeholder': {
      color: colors.flatDarkBlue,
      opacity: 1,
      fontFamily: 'HKGrotesk',
      fontSize: convertToRem(16),
      lineHeight: convertToRem(19),
      fontWeight: 400,
    },
  },
  underline: {
    '&:before': {
      borderBottom: `${convertToRem(1)} solid ${colors.flatDarkBlue}`,
    },
    '&:hover:not(.Mui-disabled):before': {
      borderBottom: `${convertToRem(1)} solid ${colors.flatDarkBlue}`,
    },
    '&.Mui-focused:after': {
      borderColor: colors.flatDarkPurple,
    },
    '&.Mui-error:after': {
      borderColor: colors.flatError,
    },
  },
  error: {
    '&.Mui-error': {
      color: colors.flatError,
    },
  },
});

const InputField: React.FC<
  Omit<TextFieldProps, 'fullWidth' | 'classes' | 'InputLabelProps' | 'InputProps' | 'FormHelperTextProps'>
> = (props) => {
  const classes = getStyles();

  return (
    <>
      <TextField
        fullWidth
        classes={{
          root: classes.root,
        }}
        InputLabelProps={{
          shrink: true,
        }}
        InputProps={{
          classes: {
            input: classes.input,
            underline: classes.underline,
          },
          className: 'paragraph-large-bold',
        }}
        FormHelperTextProps={{
          className: 'paragraph',
          classes: {
            error: classes.error,
          },
        }}
        {...(props as TextFieldProps)}
      />
    </>
  );
};

export default InputField;

import React, { useEffect } from 'react';
import styles from './MissionStart.module.scss';
import { useMediaQuery } from 'react-responsive';
import getClasses from '../../../utils/getClasses';
import MissionCounterParagraph from '../MissionCounterParagraph/MissionCounterParagraph';
import RoundButton from '../../../components/RoundButton/RoundButton';
import { fadeIn, fadeOut } from '../../../utils/fadeElements';
import gsap from 'gsap';
import { CommonInnerNativagionProps } from '../../../hooks/useInnerNavigator';
import MissionIllustrations from '../MissionIllustrations/MissionIllustrations';
import MissionStartProps from './MissionStart.models';

const MissionStart: React.FC<MissionStartProps & Pick<CommonInnerNativagionProps, 'onComplete'>> = ({
  onComplete,
  title,
  description,
  endDescription,
  illustrations,
}) => {
  const extra = useMediaQuery({ minDeviceWidth: 768 });
  const targetsToFade = [`.${styles['mission-counter']}`, `.${styles.title}`, `.${styles.description}`];

  useEffect(() => {
    gsap.to(`.${styles['btn-container']}`, { opacity: 1, duration: 0.7, delay: 0.1 });
    gsap.to('.image', {
      opacity: 1,
      duration: 0.7,
      delay: 0.1,
      stagger: (index) => [0.3, 0.4][index],
    });
    fadeIn(targetsToFade);
  }, []);

  const onTap = () => {
    gsap.to(`.${styles['btn-container']}`, { opacity: 0, duration: 0.7, delay: 0.1 });
    gsap.to('.image', {
      opacity: 0,
      duration: 0.7,
      delay: 0.1,
      stagger: (index) => [0.3, 0.4][index],
    });
    fadeOut(targetsToFade).then(onComplete);
  };

  return (
    <div className={`primary-container ${styles['custom-container']}`}>
      <MissionIllustrations hidden illustrations={illustrations} />
      <div className={styles.content}>
        <MissionCounterParagraph className={styles['mission-counter']} />
        <h1 className={getClasses({ [styles.title]: true, 'page-title': true })}>{title}</h1>
        <p className={`paragraph ${styles.description}`}>
          {description}
          {endDescription && (
            <span className={`paragraph-bold ${styles['pie-paragraph']}`}>{endDescription}</span>
          )}
        </p>
      </div>
      <div className={styles['btn-container']}>
        <RoundButton onClick={onTap} />
      </div>
    </div>
  );
};

export default MissionStart;

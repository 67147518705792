import React from 'react';
import { useEffect } from 'react';
import './ScrollIndicator.scss';
import { gsap, Quad } from 'gsap';
import { ScrollTrigger } from 'gsap/all';
import { useRef } from 'react';

const ScrollIndicator: React.FC = () => {
  const scrollIndicator = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    gsap.fromTo(scrollIndicator.current, { y: -100 }, { y: 100, repeat: -1, duration: 1.3, ease: Quad.easeInOut });
  }, []);

  return (
    <div className="scroll-indicator-wrapper">
      <div className="scroll-indicator" ref={scrollIndicator}></div>
    </div>
  );
};

export default ScrollIndicator;

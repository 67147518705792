import React, { useLayoutEffect, useRef } from 'react';
import TextButtonProps from './TextButton.models';
import './style.scss';
import { gsap, Linear }  from 'gsap';
import { DrawSVGPlugin } from 'gsap/all';
import getClasses from '../../utils/getClasses';
import useFocus from '../../hooks/useFocus';
import mouseEnterHandlerAnimation from '../../utils/mouseEnterHandlerAnimation';

const  TextButton = ({ text, svgPath, color, textLarge, onClick }: TextButtonProps): JSX.Element => {
  const tl = useRef<gsap.core.Timeline | null>(null);
  const textButtonRef = useRef<HTMLDivElement>(null);
  const ellipse = useRef<SVGPathElement>(null);
  const textWrapper = useRef<HTMLButtonElement>(null);
  const q = gsap.utils.selector(textButtonRef);
  useFocus(textWrapper, 'with-keyboard');

  const animate = () => {
    return gsap.timeline({ ease: Linear.easeIn })
    .fromTo(q('.ellipse-ref'), { drawSVG: '75% 75%' }, { delay: 0.8, duration: .4, drawSVG: '-25% 75%' })
    .fromTo(q('.text-wrapper'), { y: 15, opacity: 0 }, { y: 0, opacity: 1, duration: .3 });
  };

  useLayoutEffect(()=> {
    gsap.registerPlugin(DrawSVGPlugin);

    tl.current = animate();
    return () => {
      tl.current?.kill();
    };
  }, []);

  const clickHandler = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    tl.current?.fromTo(q('.text-wrapper'), { y: 0 } ,{ y: -24, duration: .3 })
      .fromTo(q('.ellipse-ref') ,{ drawSVG: '-25% 75%' }, { drawSVG: '-25% -25%', duration: .3 }, '<')
      .fromTo(q('.text-wrapper'), { y: 24 }, { y: 0, duration: .3 }).then(animate);
      onClick(e);
  };

  return (
    <div
      onMouseEnter={() => mouseEnterHandlerAnimation(tl, textWrapper)}
      onClick={clickHandler}
      className="btn-container"
      style={{ color: color }}
      ref={textButtonRef}
    >
      <div className="cover-animated">
        <svg viewBox="0 0 218 65" height="100%" width="100%" xmlns="http://www.w3.org/2000/svg" fill="none" preserveAspectRatio="none">
            <filter id="filter">
              <feTurbulence baseFrequency="0.2 0.8" type="turbulence" numOctaves="5" result="turbulence" seed="25"/>
              <feDisplacementMap in="SourceGraphic" scale="4" in2="turbulence" />
              </filter>
            <path className="ellipse-ref" ref={ellipse} d="M213.25 30C213.25 33.7548 210.529 37.5048 205.177 41.0522C199.855 44.5804 192.103 47.7867 182.458 50.4911C163.178 55.8968 136.499 59.25 107 59.25C77.5011 59.25 50.8224 55.8968 31.542 50.4911C21.8966 47.7867 14.1454 44.5804 8.82297 41.0522C3.47146 37.5048 0.75 33.7548 0.75 30C0.75 26.2452 3.47146 22.4952 8.82297 18.9478C14.1454 15.4196 21.8966 12.2133 31.542 9.50895C50.8224 4.10323 77.5011 0.75 107 0.75C136.499 0.75 163.178 4.10323 182.458 9.50895C192.103 12.2133 199.855 15.4196 205.177 18.9478C210.529 22.4952 213.25 26.2452 213.25 30Z" 
              strokeWidth="2" filter="url(#filter)" stroke='currentColor' />
        </svg>
      </div>
      <div className='text-button-small text-container'>
        <button className={getClasses({ 
          'btn': true,
          'text-wrapper': true,
          [textLarge ? 'text-button-large' : 'text-button-small']: true,
        })}
          ref={textWrapper} >
          { text }
          { svgPath && <svg width="24" height="24" viewBox="0 0 24 24" className="icon"><use xlinkHref={ svgPath } href={ svgPath }></use></svg> }
        </button>
      </div>
    </div>
  );
};

export default TextButton;

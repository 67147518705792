import { gsap, } from 'gsap';
import { ScrollTrigger } from 'gsap/all';
import React, { useEffect, useLayoutEffect } from 'react';
import { useHistory } from 'react-router';
import { Routes } from 'src/App';
import TextButton from 'src/components/TextButton/TextButton';
import useIllustrationsAsset from 'src/hooks/useIllustrationsAsset';
import { fadeIn } from 'src/utils/fadeElements';
import './NotFoundPage.scss';

const NotFoundPage: React.FC = () => {
  const history = useHistory();
  const goToHome = () => history.push(Routes.Root);
  const illustrations = useIllustrationsAsset('clothes');


  useLayoutEffect(() => {
    gsap.registerPlugin(ScrollTrigger);
    // const anim = fadeIn(['.not-found-container .fade-anim']);
    const anim = gsap.timeline();

    ScrollTrigger.matchMedia({
      '(max-width: 1023px)': () => {
        anim.fromTo('.not-found-container .clothest-0', {
          y: 80,
          opacity: 0
        },
        {
          y: 0,
          opacity: 1,
          duration: 0.8
        }, 0.4)
        .fromTo('.not-found-container .clothest-1', {
          y: 80,
          opacity: 0
        },
        {
          y: 0,
          opacity: 1,
          duration: 0.8
        }, 0.8);
      },
      '(min-width: 1024px)': () => {
        anim.fromTo('.not-found-container .clothest-0', {
          y: 0,
          opacity: 0
        },
        {
          yPercent: -50,
          opacity: 1,
          duration: 0.8
        })
        .fromTo('.not-found-container .clothest-1', {
          y: 0,
          opacity: 0
        },
        {
          yPercent: 50,
          opacity: 1,
          duration: 0.8
        }, 0.04);
      },
    });

    anim
    .fromTo('.not-found-container .fade-anim',
    { y: 80, opacity: 0 },
    { opacity: 1, y: 0, stagger: 0.08, duration: 0.8 }
    , 0.08);

    return () => {
      anim.time(0).kill();
    };
  }, []);
  return (
    <>
      <div className="container-fluid not-found-container">
        <div className="row row-not-found">
          <div className="col-lg-10 offset-lg-1 col-xl-8 offset-xl-2">
            <div className="row">
              <div className="col-lg-5">
                <div className="container-clothest">
                  {illustrations.map((item, index) => (
                    <img
                      key={index}
                      src={item.src}
                      alt={item.alt}
                      className={`clothest-img clothest-${index}`}
                    />
                  ))}
                </div>
              </div>
              <div className="col-lg-6 offset-lg-1">
                <p className="label-not-found fade-anim">404</p>
                <h1 className="title-extra-small fade-anim">Questo dossier è top secret</h1>
                <p className="paragraph fade-anim">Torna alla home e completa le missioni.</p>
              </div>
              <div className="col-12">
                <div className="actions-container fade-anim">
                  <TextButton
                    text="Torna alle missioni"
                    onClick={goToHome}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NotFoundPage;

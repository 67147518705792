import './MissionIllustrations.scss';
import React from 'react';
import getClasses from '../../../utils/getClasses';

export interface Illustration {
  src: string;
  alt: string;
}

interface MissionIllustrationsProps {
  illustrations: [Illustration, Illustration];
  hidden?: boolean;
  thumbnail?: boolean;
}

const MissionIllustrations: React.FC<MissionIllustrationsProps> = ({ illustrations, hidden, thumbnail }) => {
  return (
    <div className="illustrations-container">
      {illustrations.map(({ src, alt }) => (
        <img
          key={`illustration-${alt}`}
          className={getClasses({ image: true, hidden, thumbnail })}
          aria-hidden
          src={src}
          alt={alt}
        />
      ))}
    </div>
  );
};

export default MissionIllustrations;
